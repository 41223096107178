"use client"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import { Description } from "../shared/description/description"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import PropTypes from "prop-types"
import ButtonAction from "../shared/button-action/button-action"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { useDatalayer } from "@/hooks/use-datalayer"
import { usePathname } from "next/navigation"
import { Suspense } from "react"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { cn } from "@/lib/utils"

export function SuspendedAboutBrand({
  logo_icon_url,
  title,
  description,
  cta_label_button,
  href,
}) {
  const isMobile = useIsMobile()
  const { execute: login } = useReqUrl()
  const datalayer = useDatalayer()
  const pathname = usePathname()
  const { isLogin, isLoading } = useIsLogin()

  return (
    <div
      className={clsx(
        "w-full h-full relative text-text-2",
        isMobile ? "pt-[50px] px-3.5" : "pt-[77px] px-12"
      )}
    >
      <div className="flex items-center gap-[15px]">
        <div
          className={clsx(
            "relative aspect-square",
            isMobile ? "w-[80px]" : "w-[112px]"
          )}
        >
          <Image alt="about-icon" src={logo_icon_url} />
        </div>
        <h2
          className={clsx(
            "font-bold capitalize font-font-family-3",
            isMobile
              ? cn(
                  "text-4xl max-w-[230px]",
                  process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
                    ? "text-[22px] leading-[30px]"
                    : process.env.NEXT_PUBLIC_NAME === "marlboro"? "text-[34px]": ""
                )
              : "text-[40px] leading-[50px] max-w-[252px]"
          )}
        >
          {title}
        </h2>
      </div>
      <Description
        className={clsx(
          "my-[15px] font-medium font-font-family-6",
          isMobile ? "text-sm w-[320px]" : "text-base w-[560px]"
        )}
        description={description}
      />
      {isLogin && !isLoading ? (
        <Link href={href} passHref>
          <ButtonAction
            className="text-base rounded-[10px] px-[20px] py-[15px] w-fit min-w-[144px]"
            intent="primary"
            onClick={() => {
              datalayer.push({
                event: `general_event`,
                event_name: `click_${cta_label_button}_button`,
                feature: "about",
                button_name: cta_label_button,
                page_path: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
                campaign_name: "all",
              })
            }}
          >
            {cta_label_button}
          </ButtonAction>
        </Link>
      ) : (
        <ButtonAction
          className="text-base rounded-[10px] px-[20px] py-[15px] w-fit min-w-[144px]"
          intent="primary"
          onClick={() => {
            datalayer.push({
              event: `general_event`,
              event_name: `click_${cta_label_button}_button`,
              feature: "login",
              button_name: cta_label_button,
              page_path: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
              campaign_name: "all",
            })
            login()
          }}
        >
          {cta_label_button}
        </ButtonAction>
      )}
    </div>
  )
}

SuspendedAboutBrand.propTypes = {
  logo_icon_url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  cta_label_button: PropTypes.string,
  href: PropTypes.string,
}

function AboutBrandFallback() {
  return <div>Fallback</div>
}

export function AboutBrand(props) {
  return (
    <Suspense fallback={<AboutBrandFallback />}>
      <SuspendedAboutBrand {...props} />
    </Suspense>
  )
}
