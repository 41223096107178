"use client"
import { AnimatePresence, motion } from "framer-motion"
import { usePathname } from "next/navigation"
import React from "react"

const FadeComponent = ({
  children,
  delayIn = 0,
  duration = 0.2,
  delayOut,
}) => {
  const key = usePathname()
  let transitionIn = { duration: duration, ease: "easeIn", delay: delayIn }
  let transitionOut = { duration: duration, ease: "easeIn", delay: delayOut }
  return (
    <AnimatePresence>
      <motion.div
        viewport={{ once: true }}
        key={key}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: transitionIn }}
        exit={{ opacity: 0, transition: transitionOut }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default FadeComponent
