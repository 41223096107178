import CheckBox from "@/components/edit-profile/checkbox";
import DateInput from "@/components/edit-profile/date-input/date-full";
// import DateShort from "@/components/edit-profile/date-input/date-short"
import FormLabelGroup from "@/components/edit-profile/form-label-group";
import OTPInput from "@/components/edit-profile/otp-input";
import PasswordInput from "@/components/edit-profile/password-input";
import PhoneInput from "@/components/edit-profile/phone-input";
import { SelectInput } from "@/components/edit-profile/select-input";
import TextArea from "@/components/edit-profile/text-area";
import TextInput from "@/components/edit-profile/text-input";
import Link from "@/components/shared/link/link";
import React from "react";

export default function useRenderForm() {
  const handleRenderLabel = (label, link_label, link, handlerFunc, required = false) => {
    if (!link_label)
      return <FormLabelGroup className="text-text-2">
        <label>{label} {required ? <span className="text-text-1 ml-[2px]">*</span> : null}</label>
      </FormLabelGroup>;
    return (
      <FormLabelGroup>
        <label className="text-text-2">{label} {required ? <span className="text-text-1 ml-[2px]">*</span> : null}</label>
        {link === "#" ? (
          <div
            role="button"
            tabIndex={0}
            className="text-[12px] leading-[16px] underline leading-[16px] text-text-2"
            onClick={handlerFunc}
          >
            {link_label}
          </div>
        ) : (
          <Link
            href={link}
            className="text-[12px] leading-[16px] underline leading-[16px] text-text-2"
          >
            {link_label}
          </Link>
        )}
      </FormLabelGroup>
    );
  };

  const handleRenderInput = (type, props) => {
    switch (type) {
      case "text":
        return <TextInput {...props} />;
      case "phone":
        return <PhoneInput {...props} />;
      case "date":
        return <DateInput {...props} />;
      case "select":
        return <SelectInput {...props} />;
      case "textarea":
        return <TextArea {...props} />;
      case "checkbox":
        return <CheckBox label={props.placeholder} {...props} />;
      case "password":
        return <PasswordInput {...props} />;
      case "otp":
        return <OTPInput {...props} />;
      case "shortdate":
        return <DateInput isShort={true} {...props} />;
      default:
        return null;
    }
  };
  return [handleRenderLabel, handleRenderInput];
}
