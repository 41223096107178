"use client"
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import Style from "./index.module.css"
import DOMPurify from "isomorphic-dompurify"
import clsx from "clsx"
import ButtonAction from "@/components/shared/button-action/button-action"
import { useDebouncedCallback } from "use-debounce"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { cn } from "@/lib/utils"

export const variants = {
  show: {
    opacity: 1,
    x: 0,
    zIndex: 4,
    display: "block",
  },
  hide: {
    opacity: 0,
    x: 100,
    zIndex: 0,
    display: "none",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.3,
  },
}
export default function HowToInstruction({
  how_to_join_description,
  how_to_join_title,
}) {
  const descRef = useRef(null)
  const [isTheEnd, setIsTheEnd] = useState(false)
  const isMobile = useIsMobile()

  const onScroll = useDebouncedCallback(() => {
    setIsTheEnd(
      Math.abs(
        descRef.current?.scrollHeight -
          (descRef.current?.scrollTop + descRef.current?.clientHeight)
      ) <= 1
    )
  }, 200)

  useEffect(()=>{
    setIsTheEnd( Math.abs(
      descRef.current?.scrollHeight -
        (descRef.current?.scrollTop + descRef.current?.clientHeight)
    ) <= 1)
  }, [])

  const handleColor = () => {
    switch (process.env.NEXT_PUBLIC_NAME) {
      case "djisamsoe":
        return `!bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0,_rgba(21,19,12,1)_80%)]`
      case "amild":
        return `!bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0,_rgba(255,255,255,1)_80%)]`
      case "magnum":
        return `!bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0,_rgba(25,25,25,1)_80%)]`
      case "sampoernakretek":
        return `!bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0,_rgba(253,247,222,1)_80%)]`
      case "marlboro":
        return `!bg-[linear-gradient(180deg,_rgba(12,14,16,1)_0,rgba(37,40,47,1)__80%)]`
      default:
        return `!bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0,_rgba(255,255,255,1)_80%)]`
    }
  }

  return (
    <div className="rounded-[10px] lg:border-[1px] border-bg-6 bg-bg-3 text-text-2 p-[15px] lg:p-[14px] pb-[20px] lg:p-[19px] lg:pb-[20px] lg:m-0 mb-[20px] lg:col-start-1 lg:row-start-3 max-w-[609px] w-[calc(100%-20px)] lg:w-full  mx-auto lg:max-w-none lg:max-h-[374px] relative">
      <div
        data-testid="how-to-join-title"
        className="text-[16px] lg:text-[24px] leading-[22px] lg:leading-[32px] font-bold font-font-family-7  mb-[10px]"
      >
        {how_to_join_title}
      </div>
      <div className="relative h-[100%]">
        <div
          ref={descRef}
          onScroll={onScroll}
          data-testid="how-to-join-description"
          className={cn(
            `text-[14px] lg:text-[16px] leading-[24px] lg:leading-[25px] lg:max-h-[calc(100%-32px)] pb-[30px] pr-[5px] overflow-y-scroll`,
            Style.description,
            Style.wrapper
          )}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(how_to_join_description),
          }}
        ></div>
        {!isTheEnd && !isMobile ?<div
          className={clsx(
            "hidden lg:block absolute z-[3] bottom-[30px] left-2/4 -translate-x-1/2 w-full px-[10px] pt-[40px] pb-[10px] ",
            handleColor()
          )}
        ></div>:null}
      </div>
      <motion.div
        key="animation-on-state"
        variants={variants}
        animate={!isTheEnd && !isMobile ? "show" : "hide"}
        className="block absolute bottom-[20px] left-[50%] !-translate-x-1/2 z-[3]"
      >
        <ButtonAction
          className="py-[5px] px-[10px] !text-[12px] leading-[18px] min-h-[30px]"
          intent={"secondary"}
        >
          <div className="flex items-center gap-[5px]">
            <ArrowDownIcon />
            <p>Lebih Banyak</p>
          </div>
        </ButtonAction>
      </motion.div>
    </div>
  )
}

const ArrowDownIcon = () => {
  if (process.env.NEXT_PUBLIC_NAME === "djisamsoe")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M12.6654 6L7.9987 10.6667L3.33203 6"
          stroke="url(#paint0_linear_11108_55309)"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11108_55309"
            x1="12.6654"
            y1="10.6667"
            x2="3.04617"
            y2="6.69179"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#654D2C" />
            <stop offset="0.500935" stop-color="#DFD59A" />
            <stop offset="1" stop-color="#654D2C" />
            <stop offset="1" stop-color="#654D2C" />
          </linearGradient>
        </defs>
      </svg>
    )
  if (process.env.NEXT_PUBLIC_NAME === "magnum")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M12.6654 6L7.9987 10.6667L3.33203 6"
          stroke="#ED1C24"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )

  if (process.env.NEXT_PUBLIC_NAME === "sampoernakretek")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M12.6654 6L7.9987 10.6667L3.33203 6"
          stroke="#C7D943"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6654 6L7.9987 10.6667L3.33203 6"
        stroke="#ED1C24"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

HowToInstruction.propTypes = {
  how_to_join_description: PropTypes.string,
  how_to_join_title: PropTypes.string,
}
