"use client"

import Link from "@/components/shared/link/link"
import styles from "./syaratKetentuan.module.scss"
import ButtonAction from "@/components/shared/button-action/button-action"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname } from "next/navigation"
import { cn } from "@/lib/utils"

export default function CampaignGAM() {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const back = pathname.split("/")[1]

  return (
    <section
      className={clsx(
        styles.container,
        "w-full max-w-[460px] mx-auto relative pt-[30px] text-text-2 text-xs",
        isMobile && "px-3.5"
      )}
    >
      <div className={styles.snk}>
        <h2
          className={clsx(
            styles.snk_title,
            "font-bold text-2xl text-text-2 capitalize font-font-family-3"
          )}
        >
          GO AHEAD MUSIC 2025
        </h2>
        <p
          className={clsx(
            styles.snk_title,
            "font-bold text-2xl text-text-2 capitalize font-font-family-3"
          )}
        >
          Syarat & Ketentuan
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "mt-[10px] text-text-2 text-sm"
          )}
        >
          <strong className="font-medium font-font-family-6 ">PENTING:</strong> Harap baca syarat dan ketentuan ini (“Syarat
          & Ketentuan”) sebelum mengikuti program ini. Dengan mengikuti Program
          ini, anda mengkonfirmasi bahwa anda telah membaca, memahami dan setuju
          untuk mematuhi Syarat & Ketentuan, termasuk perubahannya yang dibuat
          oleh pihak Penyelenggara dari waktu ke waktu.
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            " mt-[10px] text-text-2 text-sm"
          )}
        >
          Go Ahead Music adalah program konsumen yang ditujukan kepada perokok
          dewasa milik PT HM Sampoerna Tbk. (“Sampoerna”). Sampoerna merupakan
          produsen, penjual, pemasar serta afiliasi dari produsen yang bergerak
          di bidang bisnis tembakau dan produk yang mengandung nikotin lainnya.
          Kegiatan ini meliputi riset dan pengembangan dan/atau distribusi
          produk rokok, berbagai hasil tembakau lainnya yang dibakar maupun yang
          tidak dibakar, atau rokok elektronik/<em>e-vapors</em> (alat yang memproduksi
          aerosol dengan menguapkan larutan nikotin berperisa dan menggunakan
          energi baterai), serta produk mengandung nikotin lainnya yang
          dipasarkan dengan merek-merek dagang tertentu, termasuk merek A Mild
          (“Program”). Sampoerna menunjuk pihak ketiga dalam untuk
          menyelenggarakan acara sebagai bagian dari pemberian penghargaan atau
          <em>{' '}reward</em> dan pelaksanaan rangkaian program (“Penyelenggara”).
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            " mt-[10px] text-text-2 text-sm"
          )}
        >
          Perokok dewasa yang berpartisipasi dalam Program ini, wajib membaca,
          memahami, dan mematuhi Syarat & Ketentuan yang diuraikan di bawah ini.
        </p>
        <div className={styles.snk_desc}>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              A. Persyaratan Peserta mengikuti Program:
            </span>
            <p
              className={clsx(
                styles.snk_sub,
                "mt-[10px] text-text-2 text-sm"
              )}
            >
              Program ini berlaku di wilayah Republik Indonesia dan terbuka bagi
              setiap orang Peserta wajib memenuhi persyaratan Program sebagai
              berikut:
            </p>
            <ul className={cn(styles.snk_tab, '!list-decimal')}>
              <li>
                Peserta merupakan warga negara Indonesia, baik individu maupun
                kelompok, dan bertempat tinggal di wilayah Indonesia.
              </li>
              <li>
                Seluruh peserta, baik individu maupun kelompok, adalah perokok
                dewasa berusia minimal 21 tahun, dan memiliki identitas berupa
                KTP yang masih berlaku.
              </li>
              <li>
                Terdaftar dan memiliki akun pada <em>website</em> <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a>.
              </li>
              <li>Peserta sudah melengkapi profil akun di <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a>. </li>
              <li>
                Peserta memiliki kemampuan secara fisik dan mental untuk
                mengikuti Program.
              </li>
              <li>
                Program ini berlaku di seluruh Indonesia sesuai dengan Periode
                Program.
              </li>
              <li>
                Individu atau kelompok yang berpartisipasi dalam Program{" "}
                <em>Go Ahead Music</em> serta memenuhi Syarat & Ketentuan ini,
                selanjutnya disebut “Peserta”.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              B. Periode Program:
            </span>
            <ul className={cn(styles.snk_tab, '!list-decimal')}>
              <li>
                Program ini dimulai sejak 12 Februari dan berakhir pada 31
                Desember 2025 (“Periode Program”).
              </li>
              <li>
                Setiap karya yang tidak diterima Penyelenggara selama Periode
                Program akan secara otomatis didiskualifikasi dari partisipasi.
                Penyelenggara tidak bertanggung jawab atas karya yang hilang,
                tertunda, atau gagal terkirim.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              C. Mekanisme Program:
            </span>
            <ul className={cn(styles.snk_tab, '!list-decimal')}>
              <li>
                Pendaftaran Akun. Peserta wajib memiliki akun dan terdaftar pada
                situs <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a>.
              </li>
              <li>
                Perwakilan Kelompok. Kelompok Peserta harus menunjuk satu orang
                sebagai perwakilan yang akan menjadi titik kontak selama
                pelaksanaan Program.
              </li>
              <li>
                Cara Pendaftaran. Pendaftaran dapat dilakukan secara mandiri
                melalui situs resmi <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a> atau di lokasi acara{" "}
                <em>(venue){" "}</em>
                yang bekerjasama dengan A Mild.
              </li>
              <li>
                Pengiriman Karya Musik. Peserta wajib mengirimkan karya musik
                orisinil untuk mengikuti Program yang diselenggarakan melalui
                <em>website</em> <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a>.
              </li>
              <li>
                Submisi Go Ahead Music
                <ol className={cn(styles.snk_tab, '!list-[lower-alpha]')}>
                  <li>
                    Karya musik atau demo yang dikirimkan harus berukuran
                    maksimal 10MB, dengan format Mp3, atau WAV.
                  </li>
                  <li>
                    Karya harus dilengkapi dengan profil band/musisi dan
                    deskripsi singkat mengenai karya, termasuk genre dan
                    sub-genre.
                  </li>
                  <li>
                    Keaslian dan orisinalitas hasil karya (musik, lirik,{" "}
                    <em>artwork cover</em>) harus dapat dipertanggungjawabkan
                    oleh Peserta. Karya tidak boleh menjiplak hasil karya orang
                    lain yang dimodifikasi atau menggunakan elemen yang
                    melanggar hak cipta serta etika pembuatan karya cipta. Jika
                    terbukti terjadi pelanggaran ketentuan, hasil karya akan
                    dianulir atau dibatalkan Penyelenggara.
                  </li>
                  <li>
                    Peserta harus memastikan bahwa karya yang dikirimkan tidak
                    mengandung unsur-unsur yang dapat dianggap ofensif,
                    diskriminatif, atau melanggar norma-norma kesopanan dan
                    kesusilaan. Untuk mematuhi regulasi, menjaga netralitas dan
                    sensitivitas, serta memastikan etika dan tanggung jawab
                    sosial, karya tidak boleh memuat lirik atau visual yang
                    dapat menarik perhatian anak di bawah umur (seperti tokoh
                    kartun, mainan, pendidikan, dsb.), tidak dikaitkan dengan
                    kesehatan, olahraga, agama, politik, budaya/adat daerah, dan
                    tidak menampilkan bentuk rokok (bungkus/batang/asap) serta
                    brand/logo/simbol produk/organisasi/gerakan kemasyarakatan
                    tertentu.
                  </li>
                  <li>
                    Seluruh hak cipta dan <em>master file</em> karya musik yang
                    dikirimkan merupakan hak milik Peserta.
                  </li>
                  <li>
                    Peserta bertanggung jawab untuk memastikan bahwa karya yang
                    dikirimkan tidak melanggar hak-hak pihak ketiga, termasuk
                    tetapi tidak terbatas pada hak cipta, merek dagang, dan hak
                    privasi.
                  </li>
                  <li>
                    Peserta harus memastikan bahwa karya yang dikirimkan tidak
                    mengandung unsur-unsur yang dapat dianggap merugikan atau
                    mencemarkan nama baik pihak lain.
                  </li>
                </ol>
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <p
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              D. <em>Rewards</em>:
            </p>
            <span
              className={clsx(
                styles.snk_content,
                "text-text-2 text-sm !inline-block"
              )}
            >
              Peserta yang sudah terdaftar dan mengikuti program Go Ahead Music
              di <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a> akan mendapatkan kesempatan untuk memperoleh salah
              satu atau lebih penghargaan dari rangkaian Go Ahead Music
              <em>(“Reward”)</em>, yaitu:
            </span>
            <ul className={cn(styles.snk_tab, "!mb-[10px] !list-decimal")}>
              <li>
                Potongan harga untuk sesi latihan di studio musik yang
                berkolaborasi dengan Go Ahead Music,
              </li>
              <li>
                Potongan harga untuk sesi rekaman di studio musik yang
                berkolaborasi dengan Go Ahead Music,
              </li>
              <li>
                Menjadi penampil di showcase, gigs, dan/atau festival yang
                berkolaborasi dengan Go Ahead Music, dan/atau
              </li>
              <li>
                Karya musik dimasukkan dalam playlist Go Ahead Music di Spotify,
                dimana karya tersebut diunggah oleh Peserta.
              </li>
            </ul>
            <span
              className={clsx(
                styles.snk_content,
                " text-text-2 text-sm"
              )}
            >
              Peserta terpilih yang berkesempatan menjadi penampil di acara
              sebagaimana dimaksud dalam angka 3 akan diminta meninjau dan
              menyetujui segala syarat dan ketentuan yang berlaku di acara
              terkait.
            </span>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              E. Ketentuan Peserta Terpilih:
            </span>
            <ul className={cn(styles.snk_tab, '!list-decimal')}>
              <li>
                Penentuan Peserta Terpilih:
                <ol className={cn(styles.snk_tab, '!list-[lower-alpha]')}>
                  <li>
                    Penentuan dilakukan melalui proses seleksi berdasarkan
                    ketentuan yang telah tertera dalam Syarat & Ketentuan.
                  </li>
                  <li>
                    Kandidat Peserta terpilih diwajibkan mengikuti proses kurasi
                    dan/atau wawancara oleh Penyelenggara, sebagai proses
                    penentuan peserta terpilih atau pemenang.
                  </li>
                </ol>
              </li>
              <li>
                Pengumuman <em>Reward</em>:
                <ol className={cn(styles.snk_tab, '!list-[lower-alpha]')}>
                  <li>
                    Peserta terpilih melalui proses kurasi akan diumumkan setiap
                    bulan, mulai dari April 2025.
                  </li>
                  <li>
                    Pengumuman dilakukan Penyelenggara melalui situs dan e-mail
                    konfirmasi resmi <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a>.
                  </li>
                </ol>
              </li>
              <li>
                <em>Reward</em> tidak dapat dialihkan, dan/atau tidak dapat ditukarkan
                dengan uang tunai atau bentuk apapun, baik secara keseluruhan
                atau sebagian.
              </li>
              <li>
                Peserta yang menjadi Pemenang dengan sendirinya dinyatakan
                didiskualifikasi apabila:
                <ol className={cn(styles.snk_tab, '!list-[lower-alpha]')}>
                  <li>
                    Peserta tidak dapat dihubungi oleh Penyelenggara untuk
                    keperluan verifikasi dalam jangka waktu 2x24 jam sejak
                    pengumuman Peserta terpilih,
                  </li>
                  <li>
                    Peserta telah terpilih oleh Penyelenggara dalam Program yang
                    sama pada periode pengumuman sebelumnya
                  </li>
                  <li>
                    Peserta terpilih tidak dapat menunjukkan kelengkapan dokumen
                    pendukung pada saat proses verifikasi, seperti Kartu Tanda
                    Penduduk (KTP), dan dokumen lainnya untuk memenuhi
                    persyaratan administrasi yang berlaku sesuai jangka waktu
                    yang diberikan oleh Penyelenggara.
                  </li>
                </ol>
                Dengan ini, Peserta menyatakan setuju untuk melepaskan haknya
                sebagai Peserta terpilih untuk menerima <em>Reward</em> dan akan
                membebaskan Penyelenggara dari klaim dan/atau tuntutan
                penyerahan Hadiah dalam bentuk apapun di masa mendatang.
              </li>
              <li>
                Verifikasi dan Validasi
                <ol className={cn(styles.snk_tab, '!list-[lower-alpha]')}>
                  <li>
                    Penyelenggara berhak melakukan verifikasi dan/atau
                    pengecekan validasi data Peserta serta: (i)
                    mendiskualifikasi Peserta terpilih; dan/atau (ii) menolak
                    atau membatalkan pemberian <em>Reward</em>, apabila Peserta diduga
                    melakukan kecurangan atau pelanggaran Syarat & Ketentuan ini
                    dalam bentuk apapun.
                  </li>
                  <li>
                    Keputusan Penyelenggara bersifat mutlak dan final sehingga
                    tidak dapat diganggu gugat.
                  </li>
                </ol>
              </li>
              <li>
                Pengunggahan karya di Spotify:
                <ol className={cn(styles.snk_tab, '!list-[lower-alpha]')}>
                  <li>
                    Peserta yang terpilih diwajibkan memiliki akun Spotify.
                  </li>
                  <li>Karya harus diunggah di platform Spotify.</li>
                  <li>
                    Peserta wajib mengikuti setiap syarat dan ketentuan yang
                    ditetapkan oleh Spotify.
                  </li>
                  <li>
                    Peserta memahami dan menyetujui bahwa segala konsekuensi
                    yang mungkin timbul sehubungan dengan pengunggahan karya di
                    Spotify menjadi sepenuhnya tanggung jawab Peserta.
                  </li>
                  <li>
                    Peserta setuju untuk membebaskan Penyelenggara dari segala
                    tuntutan atau gugatan hukum yang mungkin timbul sehubungan
                    dengan pelaksanaan Program atau alasan apa pun terkait
                    pengunggahan karya di Spotify.
                  </li>
                  <li>
                    Peserta juga bertanggung jawab untuk memastikan bahwa karya
                    yang diunggah tidak melanggar hak cipta, hak kekayaan
                    intelektual, atau hak-hak lain dari pihak ketiga.
                    Penyelenggara tidak bertanggung jawab atas segala bentuk
                    pelanggaran yang dilakukan oleh Peserta terkait karya yang
                    diunggah di Spotify.
                  </li>
                </ol>
              </li>
              <li>
                Peserta terpilih sebagai penampil di showcase, gigs, dan/atau
                festival yang berkolaborasi dengan Go Ahead Music wajib
                mengikuti syarat dan ketentuan dari pihak penyelenggara acara
                terkait.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <p
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              F. Ketentuan Umum:
            </p>
            <ul className={cn(styles.snk_tab, '!list-decimal')}>
              <li>
                Program ini tidak berlaku untuk semua karyawan, keluarga
                karyawan, dan/atau pihak ketiga yang terikat kerja sama dengan
                PT HM Sampoerna Tbk dan/atau afiliasinya.
              </li>
              <li>
                Penyelenggara berhak untuk mengubah tanggal, mekanisme, <em>{' '}Reward{' '}</em>
                dan/atau Syarat & Ketentuan atas Program dari waktu ke waktu
                sepanjang konsep dan nilainya kurang lebih sama dengan yang
                diuraikan saat ini, tanpa pemberitahuan sebelumnya atau
                kompensasi dalam bentuk apapun kepada para Peserta dan/atau
                pihak manapun.
              </li>
              <li>
                Dalam hal terjadi peristiwa keadaan memaksa <em>(force majeure)</em> atau
                pertimbangan material lainnya, Penyelenggara dapat membatalkan
                penyelenggaraan Program tanpa kewajiban untuk memberikan
                pemberitahuan dan/atau kompensasi kepada Peserta atau pihak
                manapun.
              </li>
              <li>
                Penyelenggara tidak akan bertanggung jawab apabila terjadi
                penipuan dan/atau tindak pidana lainnya yang mengatasnamakan
                Program ini. Untuk itu, Peserta diminta untuk berhati-hati dan
                waspada terhadap modus penipuan dan/atau tindak pidana lainnya
                yang mungkin mengatasnamakan Program ini serta selalu memastikan
                berhubungan dengan pihak Penyelenggara yang sah terkait Program.
              </li>
              <li>
                Silakan hubungi atau periksa pihak Penyelenggara yang sah
                terkait Program pada <em>website</em> amild.id atau email ke {" "}
                <a className="underline" href="mailto:info@amild.id">info@amild.id</a>.
              </li>
              <li>
                Setiap Peserta yang mengikuti Program ini menjamin bahwa setiap
                informasi yang diberikan adalah benar, tepat dan akurat selama
                periode Program.
              </li>
              <li>
                Dengan mengikuti Program ini, Peserta setuju atas dan memberikan
                izin kepada PT HM Sampoerna Tbk. dan/atau afiliasinya untuk:
                <ol className={cn(styles.snk_tab, '!list-[lower-alpha]')}>
                  <li>
                    Memperoleh, mengumpulkan, mengolah, menganalisis, menyimpan,
                    menampilkan, mengumumkan, mengirimkan, mempublikasikan
                    dan/atau menyebarkan data pribadi Peserta dan setiap
                    Pemenang (antara lain namun tidak terbatas kepada, informasi
                    nama, foto, nomor identitas, email, nomor telepon) namun
                    terbatas untuk keperluan verifikasi Peserta;
                  </li>
                  <li>
                    Menghubungi Peserta secara langsung ataupun melalui pihak
                    ketiga yang telah ditunjuk;
                  </li>
                  <li>
                    Mengungkapkan informasi data pribadi Peserta kepada PT HM
                    Sampoerna Tbk. dan/atau afiliasinya untuk melakukan hal-hal
                    di atas serta kepada pihak yang berwenang (jika disyaratkan
                    oleh hukum yang berlaku di Negara Republik Indonesia);
                    dan/atau
                  </li>
                  <li>
                    Membuat dokumentasi dalam format apapun (foto atau video)
                    rekaman video yang berisi antara lain, video, nama, dan
                    komentar dari Peserta atau Pemenang untuk keperluan
                    publikasi di dalam situs <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a> atau materi publikasi
                    lainnya dari A Mild dan PT HM Sampoerna Tbk. Dengan ini,
                    Peserta menyetujui bahwa segala hak ekonomis yang mungkin
                    timbul dari publikasi tersebut sepenuhnya menjadi milik
                    Penyelenggara, dan
                  </li>
                  <li>
                    Peserta tidak dapat menuntut hak ekonomis apapun yang
                    mungkin timbul dari publikasi dimaksud.
                  </li>
                </ol>
              </li>
              <li>
                Seluruh Hak Cipta dan Hak atas Kekayaan Intelektual (HAKI)
                termasuk <em>master file</em> dari karya musik yang dikirimkan oleh
                Peserta ke <em>website</em> <a href="/" className="underline" target="_blank" referrerPolicy="no-referrer">www.amild.id</a> merupakan hak milik Peserta.
              </li>
            </ul>
          </div>
        </div>
        <Link href={`/${back}/br`} passHref>
          <ButtonAction
            intent="primary"
            className={styles.snk_ctaBtn}
            fullWidth
          >
            Kembali
          </ButtonAction>
        </Link>
      </div>
    </section>
  )
}
