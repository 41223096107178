import { ClientApiList } from "@/lib/api/client-service"
import { useRouter, usePathname, useSearchParams } from "next/navigation"
import { useState } from "react"
import { useForm } from "react-hook-form"

const useEditEmail = ({
  setIsEmailInvalid,
  setIsSuccess,
  setIsError,
  captchaRef,
}) => {
  const pathname = usePathname()
  const { replace } = useRouter()
  const searchParams = useSearchParams()
  const handleGetData = async () => {
    const { data } = await ClientApiList.getProfileData()
    const dob = new Date(data.data.data.dob)
    const date = dob.getDate()
    const month = dob.getMonth() + 1
    const year = dob.getFullYear()
    const fav_brand = data.data.data.favorite_brand.brand_id
    const old_email = data.data.data.email
    return {
      ...data.data.data,
      date,
      month,
      year,
      brand_id: fav_brand,
      password: "",
      email: "",
      old_email,
      "g-recaptcha-response": false,
    }
  }

  const {
    // reset: formReset,
    watch,
    register,
    getValues,
    handleSubmit,
    setValue,
    reset: resetForm,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: async () => handleGetData(),
  })

  const resetFormValue = async () => {
    const result = await handleGetData()
    resetForm(result)
    // captchaRef.current?.execute();
  }

  const [formError, setFormError] = useState(null)

  const reset = () => {
    setFormError(null)
  }

  const onSubmit = async (formData) => {
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()
    setFormError(null)

    if (!token) {
      setIsError(true)
      captchaRef.current?.execute()
      replace(pathname)
      return
    } else {
      setIsError(false)
      try {
        // const result = await ClientApiList.checkPassword({ password: formData.password, login_name: formData.old_email || formData.phone });
        // if (result.status === 200) {

        const zbBody = { email: formData.email }
        const { data } = await ClientApiList.verifyEmailZB({ ...zbBody })

        if (data.data.data.is_valid) {
          const body = {
            email: formData.email,
            old_email: formData.old_email,
            password: formData.password,
            "g-recaptcha-response": token,
          }
          const response = await ClientApiList.updateEmail({ ...body })

          if (response.status === 200) {
            setIsSuccess(true)
            resetFormValue()
            setTimeout(() => {
              searchParams.has("uuid")
                ? replace(
                    `/event/reservation/br?uuid=${searchParams.get("uuid")}`
                  )
                : replace("/profil/ubah-profil/br")
            }, [1800])
          } else {
            setError("email", { message: response?.error.message })
            setError("password", { message: response?.error.message })
            setFocus("email")
            // captchaRef.current?.execute();
            return
          }
        } else {
          setIsEmailInvalid(true)
          // captchaRef.current?.execute();
          return
        }
        // } else {
        //     setError('email', { message: result?.error.message });
        //     setError('password', { message: result?.error.message });
        //     setFocus('email');
        // }
      } catch (err) {
        setError("email", { message: err?.message })
        setError("password", { message: err?.message })
        setFocus("email")
        // captchaRef.current?.execute();
        return
      }
    }
  }

  return {
    register,
    getValues,
    errors,
    formError,
    reset,
    watch,
    setValue,
    onSubmit: handleSubmit(onSubmit),
  }
}

export default useEditEmail
