"use client"
import React, { useRef, useState } from "react"
import ItemCard from "./components/item-card/item-card"
import { SwiperSlide } from "swiper/react"
import "swiper/css"
import { SwiperComp } from "@/components/swiper/swiper"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import ButtonAction from "@/components/shared/button-action/button-action"

export default function LimitedItem({
  limitedItems = [],
  isLoading,
  handleClaim,
}) {
  const swiperRef = useRef(null)
  const isDesktop = useIsDesktop()
  const [activeIndex, setActiveIndex] = useState(0)
  const showButton = swiperRef.current
    ? swiperRef.current?.virtualSize < limitedItems?.length * 255 && activeIndex !== limitedItems?.length -3
    : 0

  const handleRenderList = () => {
    if (isLoading)
      return [1, 2, 3, 4, 5].map((item, index, arr) => (
        <SwiperSlide
          key={index}
          className={`!w-fit ${index === limitedItems.length - 1 ? "!mr-[10px]" : ""}`}
        >
          <Skeleton />
        </SwiperSlide>
      ))
    else
      return limitedItems.map((item, index) => {
        return (
          <SwiperSlide
            key={index}
            className={`!w-fit !h-[auto] ${index === limitedItems.length - 1 ? "!mr-[10px]" : ""}`}
          >
            <ItemCard
              {...{
                title: item.masking_title,
                itemImg: item.imageurl,
                validDate: item.valid_date,
                exp: item.point,
                expIcon: item.exp_icon,
                handleClaim,
                id: item.is_aspace ? item.aspace_id : item.id,
                aldmic_id:item.id,
                is_aspace: item.is_aspace || 0,
                end_date: item.end_date,
              }}
            />
          </SwiperSlide>
        )
      })
  }

  const handleNextSlide = () => {
    if (activeIndex < limitedItems.length) {
      swiperRef?.current?.slideNext()
      setActiveIndex((prevState) => prevState + 1)
    }
  }
  const handlePrevSlide = () => {
    if (activeIndex !== 0) {
      swiperRef?.current?.slidePrev()
      setActiveIndex((prevState) => prevState - 1)
    }
  }

  if (!isLoading && limitedItems.length === 0) return

  return (
    <>
      <div className="text-text-2 pl-[5px] lg:pl-0 text-[16px] lg:text-[20px] font-medium font-font-family-6 mb-[10px] leading-[22px] lg:leading-[27px] flex items-center justify-between">
        Limited Item
        <div className="hidden relative lg:flex items-center gap-[5px]">
          <ButtonAction
            disabled={activeIndex === 0}
            onClick={handlePrevSlide}
            className="min-h-[30px] min-w-[30px] w-[100%] h-[100%] !max-w-[30px] !max-h-[30px] flex items-center justify-center !p-0"
            intent={`${activeIndex === 0 ? "primary_disable" : "primary"}`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 19L8 12L15 5"
                className={`${activeIndex === 0 ? "stroke-icon-3" : "stroke-icon-4"}`}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {/* <Image className={`${Style.img} ${activeIndex === 0 ? Style.disable : Style.active} max-w-[20px] max-h-[20px] fill-text-3`} src="/assets/amild/icons/chevron-left.svg" alt="prev" /> */}
          </ButtonAction>
          <ButtonAction
            disabled={!showButton}
            onClick={handleNextSlide}
            className="min-h-[30px] min-w-[30px] w-[100%] h-[100%] !max-w-[30px] !max-h-[30px] flex items-center justify-center !p-0"
            intent={`${!showButton ? "primary_disable" : "primary"}`}
          >
            {/* <Image className={`${Style.img} ${!showButton ? Style.disable : Style.active} max-w-[20px] max-h-[20px] fill-text-3`} src="/assets/amild/icons/chevron-right.svg" alt="prev" /> */}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`${!showButton ? "stroke-icon-3" : "stroke-icon-4"}`}
                d="M9 5L16 12L9 19"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ButtonAction>
        </div>
      </div>
      <div className="relative mb-[15px] min-h-[266px] flex gap-[5px] mb-[30px]">
        <div className="absolute left-[-10px] lg:left-[0] right-[-15px] lg:right-0">
          <SwiperComp
            ref={swiperRef}
            className="!pl-[10px] lg:!p-0"
            slidesPerView={"auto"}
            spaceBetween={isDesktop ? 10 : 5}
          >
            {handleRenderList()}
            {/* {limitedItems.map((item, index) => {
                            return (
                                <SwiperSlide key={index} className={`!w-fit ${index === limitedItems.length - 1 ? '!mr-[10px]' : ''}`}>
                                    <ItemCard {...{ title: item.title, itemImg: item.item_img, validDate: item.valid_date, exp: item.exp, expIcon: item.exp_icon }} />
                                </SwiperSlide>
                            );
                        })} */}
          </SwiperComp>
        </div>
      </div>
    </>
  )
}

function Skeleton() {
  return (
    <div className=" animate-pulse border-[1px] flex flex-col border-cta-4 p-[5px] rounded-[10px] bg-bg-3 w-[100vw] h-[100vh] max-w-[228px] max-h-[247px]">
      <div className="w-[100vw] bg-[#D9D9D9] h-[100vh] relative rounded-[10px] max-w-[218px] max-h-[109px] overflow-hidden mb-[5px]"></div>
      <div className="p-[5px] pb-[10px] grow-[1] flex flex-col">
        <div className="h-[21px] w-[100%] bg-[#D9D9D9] mb-[2px]"></div>
        <div className="h-[21px] w-[20%] bg-[#D9D9D9] mb-[5px]"></div>
        <div className="h-[19px] w-[80%] bg-[#D9D9D9] mb-[20px] text-text-3"></div>
        <div className="h-[19px] w-[40%] bg-[#D9D9D9] mt-auto font-bold font-font-family-7 text-cta-1 text-[14px] leading-[19px] flex items-center gap-[5px] "></div>
      </div>
    </div>
  )
}
