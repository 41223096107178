"use client"
import React, { Suspense, useState } from "react"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import PropTypes from "prop-types"
// custom comp. import
import ShowQrButton, { ShowQrButtonFallBack } from "./show-qr-button"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { formatNumber } from "@/components/edit-profile/date-input/helper"
import { cn } from "@/lib/utils"
import { urlAsset } from "@/helper/utils"
import { AnimatePresence, motion } from "framer-motion"
import FadeComponent from "@/components/shared/fade-component/fade-component"
export default function UserPassport({ isLoading, ...props }) {
  const isDesktop = useIsDesktop()
  const [isShowTooltip, setIsShowTooltip] = useState(false)

  const textClass = process.env.NEXT_PUBLIC_NAME === "marlboro" ? 'text-icon-4' :'text-text-2'

  const handleRenderTier = () => {
    return (
      <>
        <div className={cn(`text-[12px] mb-[10px] leading-[9px] text-text-2 tracking-[0.24px] font-medium font-font-family-6 lg:text-[14px] lg:mb-[15px] lg:leading-[10px]`, textClass)}>
          {isLoading ? "Current Tier" : current_tier ? "Current Tier" : ""}
        </div>
        {!isLoading ? (
          <div className={cn("text-[18px] text-text-2 font-bold font-font-family-7 leading-[13px] tracking-[-0.18px] mb-[15px] lg:mb-[20px] flex items-center gap-[5px]", textClass)}>
            <span data-testid="user-passport-tier">
              {current_tier ? current_tier : <div className="h-[18px]"></div>}
            </span>
            {current_tier ? (
              <Link href="/about/br#benefit">
                <Image
                  className="w-[20px] h-[20px]"
                  src={`${urlAsset()}/user-profile/info.svg`}
                  alt="info"
                />
              </Link>
            ) : (
              <div className="h-[20px] w-[20px]"></div>
            )}
          </div>
        ) : (
          <div className="h-[20px] max-w-[50%]  bg-[#D9D9D9] animate-pulse tracking-[-0.18px] mb-[15px] lg:mb-[20px]"></div>
        )}
      </>
    )
  }

  const handleRenderPoints = () => {
    return (
      <>
        <div className={cn("text-[12px] lg:text-[14px] mb-[10px] leading-[9px] lg:leading-[10px] text-text-2 tracking-[0.24px] lg:tracking-[0.28px] font-medium font-font-family-6 lg:text-[14px] lg:mb-[15px] lg:leading-[10px]", textClass)}>
          {isLoading
            ? "Poin Accumulation"
            : accumulated_exp
              ? "Poin Accumulation"
              : ""}
        </div>
        {!isLoading ? (
          <div className="text-[18px] text-text-1 font-bold font-font-family-7 leading-[20px] lg:leading-[13px] tracking-[-0.18px] flex items-center gap-[5px] lg:mb-[20px] relative z-[3]">
            <div
              className={`${isShowTooltip ? "block" : "hidden"} absolute lg:h-fit py-[5px] px-[10px] bg-bg-5 bottom-[-85px] right-[-10px] lg:bottom-unset lg:top-[-10px] lg:left-[60%] z-[15] rounded-[5px] text-text-4 text-[12px] font-medium font-font-family-6 tracking-[0.25px] max-w-[175px] w-[100%]`}
            >
              <div className="relative">
                <div className="h-[0] w-[0] border-t-[10px] border-b-[10px] border-y-[transparent] border-r-[13px] border-r-bg-5 top-[-18px] right-[5px] rotate-90 lg:rotate-0 lg:top-[5px] lg:left-[-20px] absolute"></div>
                Poin dan Tier akan di-<i>reset</i> setiap tahun tanggal 31
                Desember
              </div>
            </div>
            <div className="relative w-[18px] h-[18px]">
              {accumulated_exp ? (
                <Image
                  alt="cover"
                  src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
                  // src="https://images.unsplash.com/photo-1696263075473-49877750c1a2?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=300&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY5Nzc3MzAyMA&ixlib=rb-4.0.3&q=80&w=300"
                />
              ) : null}
            </div>
            <span data-testid="user-passport-points">
              {accumulated_exp ? (
                `${formatNumber(accumulated_exp)} Poin`
              ) : (
                <div className="h-[20px]"></div>
              )}
            </span>
            <div
              role="button"
              onKeyDown={() => {
                // Todo: Add keydown event
              }}
              tabIndex={0}
              onMouseEnter={() => setIsShowTooltip(true)}
              onMouseLeave={() => setIsShowTooltip(false)}
              onClick={() => setIsShowTooltip((prev) => !prev)}
            >
              {accumulated_exp ? (
                <Image
                  className="w-[20px] h-[20px]"
                  src={`${urlAsset()}/user-profile/info.svg`}
                  alt="info"
                />
              ) : (
                <div className="h-[20px] w-[20px]"></div>
              )}
            </div>
          </div>
        ) : (
          <div className="h-[20px] max-w-[60%] bg-[#D9D9D9] animate-pulse tracking-[-0.18px] mb-[15px] lg:mb-[20px]"></div>
        )}
      </>
    )
  }

  const switchBG = () => {
    if (process.env.NEXT_PUBLIC_NAME === "djisamsoe")
      return "linear-gradient(180deg, #DBB263 0%, #EFE9C5 100%)"
    else return "var(--background_3)"
  }

  const {
    accumulated_exp,
    cta_label,
    current_tier,
    account_label,
    registered_account,
    name,
    qr_img_url,
    bg_passport_mobile_url,
    bg_passport_desktop_url,
  } = props

  return (
    <FadeComponent delayIn={0.2}>
      <div className="relative shadow-[-5px_6px_0_0_rgba(0,0,0,.2)] mx-[10px] rounded-[10px] lg:flex max-w-[355px] lg:max-w-[608px] mx-auto overflow-hidden">
        <div className="absolute z-[0] inset-0  w-full h-full">
          <Image
            data-testid="user-passport-left-img"
            objectFit="contain"
            alt="cover"
            src={isDesktop ? bg_passport_desktop_url : bg_passport_mobile_url}
          />
        </div>
        <div className="bg-transparent relative pt-[14px] px-[10px] lg:p-[20px] pb-[22px] bg-bg-3 rounded-t-[10px] lg:rounded-r-[0] flex gap-[10px] border-b-[2px] lg:border-b-[0] border-dotted border-[#C0C0C0] lg:flex-col lg:gap-[20px] lg:min-h-[417px] w-[100%]">
          <div
            style={{ background: switchBG() }}
            className="h-[162px] w-full max-w-[120px] rounded-[10px] border-[1px] border-cta-2 relative z-[1] lg:min-w-[264px] lg:min-h-[162px] overflow-hidden flex items-center justify-center border-cta-4"
          >
            {current_tier ? (
              <div className="absolute z-[2] top-[0px] left-[8.24px] lg:left-[15px] min-h-[40px] min-w-[24px] lg:w-[30px] lg:h-[51px]">
                <Image
                  alt="cover"
                  src={
                    props[
                      `badge_${current_tier.toLowerCase().replace(" ", "_")}_url`
                    ]
                  }
                />
              </div>
            ) : null}
            <div
              className={cn(
                "w-[100vw] h-[100vh] lg:mb-[10px]",
                process.env.NEXT_PUBLIC_NAME === "amild"
                  ? "max-w-[79px] max-h-[79px] lg:!max-w-[88px] lg:!max-h-[88px]"
                  : process.env.NEXT_PUBLIC_NAME === "marlboro"
                    ? "max-w-[135px] max-h-[135px] lg:!max-w-[155px] lg:!max-h-[155px]"
                    : "max-w-[102px] max-h-[102px] lg:!max-w-[118px] lg:!max-h-[118px]"
              )}
            >
              <Image src={props.avatar_url} alt="avatar" />
            </div>
            <Link
              href="/profil/ubah-profil/br"
              className="absolute bottom-[10px] left-[50%] translate-x-[-50%] p-[5px] lg:pl-[8px] lg:pr-[10px] flex items-center gap-[5px] rounded-[5px] min-w-[91px] border-[1px] border-icon-1 text-cta-1 text-[10px] md:text-[12px] font-medium font-font-family-6 leading-[0.24px]"
            >
              <div
                className={cn(
                  "absolute z-[-1] inset-0 opacity-10",
                  process.env.NEXT_PUBLIC_NAME === "magnum" ||
                    process.env.NEXT_PUBLIC_NAME === "amild" ||
                    process.env.NEXT_PUBLIC_NAME === "marlboro"
                    ? "text-icon-1"
                    : " text-cta-2"
                )}
              ></div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M11.2328 2.56049C11.5919 2.20152 12.0789 1.99991 12.5867 2C13.0944 2.00009 13.5814 2.20189 13.9403 2.56099C14.2993 2.92009 14.5009 3.40709 14.5008 3.91485C14.5007 4.4226 14.2989 4.90952 13.9398 5.26849L13.2068 6.00249L10.4988 3.29449L11.2328 2.56149V2.56049ZM9.79283 4.00149L3.83683 9.95549C3.65604 10.1364 3.51979 10.3569 3.43883 10.5995L2.52483 13.3425C2.49538 13.4306 2.49104 13.5251 2.5123 13.6155C2.53356 13.7059 2.57958 13.7886 2.64519 13.8543C2.71081 13.92 2.79343 13.9662 2.8838 13.9876C2.97417 14.009 3.06871 14.0048 3.15683 13.9755L5.89983 13.0605C6.14283 12.9805 6.36283 12.8435 6.54383 12.6625L12.4998 6.70949L9.79183 4.00049L9.79283 4.00149Z"
                  // fill="#FCFBFE"
                  className="fill-icon-1"
                />
              </svg>
              <span
                className={cn(
                  "block",
                  process.env.NEXT_PUBLIC_NAME === "magnum" ||
                    process.env.NEXT_PUBLIC_NAME === "amild" ||
                    process.env.NEXT_PUBLIC_NAME === "marlboro"
                    ? "!text-icon-1"
                    : "!text-cta-2"
                )}
              >
                Edit Profile
              </span>
            </Link>
          </div>
          <div className="pt-[10px] lg:pt-0 relative z-[1]">
            <div className={cn("text-[12px] lg:text-[14px] mb-[12px] lg:mb-[15px] leading-[9px] lg:leading-[10px] text-text-2 tracking-[0.24px] lg:tracking-[0.28px] font-medium font-font-family-6", textClass)}>
              Name
            </div>
            {!isLoading ? (
              <div
                data-testid="user-passport-name"
                className={cn("text-[18px] text-text-2 font-bold font-font-family-7 leading-[20px] tracking-[-0.18px] mb-[15px] lg:mb-[20px]", textClass)}
              >
                {name}
              </div>
            ) : (
              <div className="max-w-[80%] h-[13px] bg-[#D9D9D9] animate-pulse tracking-[-0.18px] mb-[15px] lg:mb-[20px]"></div>
            )}
            {handleRenderTier()}
            {handleRenderPoints()}
          </div>
        </div>

        <div className="bg-transparent px-[10px] pt-[20px] lg:p-[20px] pb-[27px] bg-bg-3 rounded-b-[10px] lg:rounded-l-[0] flex gap-[10px] lg:flex-col lg:gap-[20px] relative z-[0] lg:min-h-[417px]">
          <div className="flex flex-col justify-between items-center relative z-[1]">
            <div className="relative h-[112px] w-full min-w-[112px] bg-bg-2 rounded-[5px] lg:rounded-[10px] mb-[10px] lg:mb-[0] lg:min-w-[264px] lg:h-[162px] overflow-hidden">
              <Image
                className="absolute hidden lg:block brightness-50"
                alt="cover"
                data-testid="user-passport-qr-img"
                src={qr_img_url}
              />
              <div className="relative  top-0 lg:top-[10px] left-[50%] translate-x-[-50%] h-[112px] w-[100%] lg:h-[108px] lg:w-[108px] bg-bg-3">
                <Image alt="cover" src={qr_img_url} />
              </div>
            </div>
            <Suspense fallback={<ShowQrButtonFallBack />}>
              <ShowQrButton cta_label={cta_label} />
            </Suspense>
          </div>
          <div className="pt-[9px] lg:pt-0 grow-[1] flex flex-col justify-start lg:justify-start relative z-[1]">
            <div
              data-testid="user-passport-account-label"
              className={cn("text-[12px] lg:text-[14px] mb-[12px] lg:mb-[15px] leading-[9px] lg:leading-[10px] text-text-2 tracking-[0.24px] lg:tracking-[0.28px] font-medium font-font-family-6", textClass)}
            >
              {account_label || "Registered Email"}
            </div>
            {!isLoading ? (
              <div
                data-testid="user-passport-account"
                className={cn("break-all text-[18px] font-bold font-font-family-7 leading-[20px] mb-[22px] lg:mb-[20px] text-text-2", textClass)}
              >
                {registered_account}
              </div>
            ) : (
              <div className="h-[11px] lg:h-[13px] max-w-[80%] bg-[#D9D9D9] animate-pulse tracking-[-0.18px] mb-[15px] lg:mb-[20px]"></div>
            )}
          </div>
        </div>
      </div>
    </FadeComponent>
  )
}

UserPassport.propTypes = {
  accumulated_exp: PropTypes.number,
  cta_label: PropTypes.string,
  current_tier: PropTypes.string,
  account_label: PropTypes.string,
  registered_account: PropTypes.string,
  name: PropTypes.string,
  qr_img_url: PropTypes.string,
}
