"use client"
import React, { useEffect, useState } from "react"
import { Image } from "../shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { ClientApiList } from "@/lib/api/client-service"
import { useRouter, useSearchParams } from "next/navigation"
import { urlAsset } from "@/helper/utils"
import { AnimatePresence, motion } from "framer-motion"
import ButtonAction from "../shared/button-action/button-action"

const ReservationOTP = ({ otp }) => {
  const isMobile = useIsMobile()
  const [seconds, setSeconds] = useState(60)
  // const [isResend, setIsResend] = useState(0)
  const { replace } = useRouter()
  const searchParams = useSearchParams()

  const handleRequestOTP = async (resend = false) => {
    const response = await ClientApiList.sendEmail()
    // resend && setIsResend(true)
    // setIsResend(true)
  }

  const handleCheckVerify = async (init = false) => {
    try {
      const { data } = await ClientApiList.submitRSVP({
        event_uuid: searchParams.get("uuid"),
      })
      if (data.data.data.is_email_verified) {
        replace(
          `/event/reservation/summary/br?uuid=${searchParams.get("uuid")}`
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleCheckVerify(true)
  }, [])

  useEffect(() => {
    let interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        console.log(interval)
        // setIsResend(isResend + 1)
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])
  // console.log(isResend, "isResend")
  const resendOTP = async () => {
    await handleRequestOTP()
    // isResend <= 1 &&
    setSeconds(59)
  }

  return (
    // <div className="mt-[58px] lg:mt-[72px] bg-bg-1">
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, ease: "easeInOut" },
        }}
        exit={{
          opacity: 0,
          y: 100,
          transition: { duration: 0.5, ease: "easeInOut" },
        }}
      >
        {process.env.NEXT_PUBLIC_NAME === "marlboro" ? null : (
          <Image
            className="absolute h-[100%] w-[100%]"
            alt="delete"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/profile-edit/${isMobile ? "bg-mobile.png" : "bg-desktop.png"}`}
          />
        )}
        <div className="py-[66px] lg:py-[90px] px-[10px] relative min-h-[calc(100dvh-114px)]">
          <div className="relative z-[3] max-w-[462px] mx-auto">
            <div className="h-[177px] w-[304px] mx-auto mb-[20px]">
              <Image
                className="aspect-[304/177]"
                src={`${urlAsset()}/profile-edit/otp.png`}
                alt="email-otp"
              />
            </div>
            <div className="text-[20px] lg:text-[24px] leading-[28px] lg:leading-[32px] font-bold text-center text-text-2 mb-[10px]">
              Verifikasi Email Lo
            </div>
            <div className="text-[16px] leading-[24px] font-[400] max-w-[325px] lg:max-w-[462px] mx-auto mb-[30px] text-center text-text-2 mb-[10px]">
              Klik link verifikasi yang telah kami kirim ke email lo untuk
              menyelesaikan proses verifikasi email.
            </div>
            {/* {isResend <= 1 ? ( */}
            <div className="text-center text-[12px] tracking-[0.2px] text-text-5">
              <div>
                {seconds > 0 ? (
                  <p>
                    Tunggu{" "}
                    <b className="text-text-2">
                      {seconds < 10 ? `0${seconds}` : seconds} detik
                    </b>{" "}
                    untuk mengirim ulang link
                  </p>
                ) : (
                  <div>
                    {"Tidak menerima link?"}
                    <button
                      type="reset"
                      value="Reset"
                      className="ml-[5px] underline font-bold font-font-family-7 text-text-1"
                      onClick={() => {
                        resendOTP()
                        // handleRequestOTP(true);
                      }}
                    >
                      {"Kirim Ulang"}
                    </button>
                  </div>
                )}
                <div className="flex items-center justify-center">
                  <ButtonAction
                    onClick={() => handleCheckVerify(false)}
                    className={
                      "max-w-[215px] mx-auto hover:underline underline mt-[10px]"
                    }
                    innerClass={"underline"}
                    intent={"tertiary"}
                  >
                    Cek Status Verifikasi
                  </ButtonAction>
                </div>
              </div>
            </div>
            {/* // ) : (
            //   <div className="flex items-center justify-center">
            //     <ButtonAction onClick={handleCheckVerify} className={'max-w-[215px] mx-auto hover:underline underline'} innerClass={'underline'} intent={"tertiary"}>Berhasil Verifikasi Email</ButtonAction>
            //   </div>
            // )} */}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
    // </div>
  )
}

export default ReservationOTP
