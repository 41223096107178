"use client"
import React, { useEffect, useState } from "react"
import BackBanner from "../shared/back-banner"
import ReservationForm from "./reservation-form"
import ReservationConfirmation from "./reservation-confirmation"
import { reservation } from "@/components/reservation/reservation"
import { ClientApiList } from "@/lib/api/client-service"
import { useRouter, useSearchParams } from "next/navigation"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { Spinner } from "../shared/spinner"
import { AnimatePresence, motion } from "framer-motion"

const ReservationWrapper = () => {
  const { replace } = useRouter()
  const [data, setData] = useState(null)
  const searchParams = useSearchParams()
  const isMobile = useIsMobile()

  const handleGetDetail = async () => {
    try {
      const { data } = await ClientApiList.getReservationForm({
        event_uuid: searchParams.get("uuid"),
      })
      if (data.data.data.current_status === "summary") {
        replace(
          "/event/reservation/summary/br?uuid=" + searchParams.get("uuid")
        )
      }
      if (data.data.data.current_status === "verify") {
        replace("/event/reservation/otp/br?uuid=" + searchParams.get("uuid"))
      }
      setData(data.data.data)
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  useEffect(() => {
    handleGetDetail()
  }, [])

  console.log(data, 'data wrap')
  if (!data)
    return (
      <div className="relative flex items-center h-[100dvh] w-[100%]">
        <Spinner />
      </div>
    )

  return (
    <AnimatePresence>
      <motion.div
      className="absolute left-0 top-0 h-[100dvh] w-[100%]"
        initial={{
          opacity: 0,
          // y: -100
        }}
        animate={{
          opacity: 1,
          // y: 0,
          transition: { duration: 0.5, ease: "easeInOut" },
        }}
        exit={{
          opacity: 0,
          // y: 100,
          transition: { duration: 0.5, ease: "easeInOut" },
        }}
      >
        <BackBanner
          className="lg:top-[15px] lg:left-[15px] lg:right-[15px] bg-bg-5 lg:h-[520px] bg-top"
          bgImg={
            isMobile
              ? data?.detail?.rsvp_mobile_image
              : data?.detail?.rsvp_desktop_image
          }
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, ease: "easeInOut" },
        }}
        exit={{
          opacity: 0,
          y: 100,
          transition: { duration: 0.5, ease: "easeInOut" },
        }}
      >
        <ReservationForm data={data} reservation={reservation} />
        <ReservationConfirmation />
      </motion.div>
    </AnimatePresence>
  )
}

export default ReservationWrapper
