"use client"
import React, { useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname, useSearchParams } from "next/navigation"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { Spinner } from "../shared/spinner"
import { useWindowSize } from "@/hooks/use-window-size"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"

export default function WordlePageLayout({
  children,
  bg_img_url_mobile,
  bg_img_url_desktop,
}) {
  const { isLoading, isLogin } = useIsLogin()
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const [showPage, setShowPage] = useState(false)

  const checkBrPath = pathname.includes("/br") && !isLogin && !isLoading
  // const checkEngagement = pathname.includes("/media_submission") || (pathname.includes('quiz') && !searchParams.get('isGameStart') === true)
  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const bgImg = process.env.NEXT_PUBLIC_NAME === "marlboro" ? null :isDesktop ? bg_img_url_desktop : bg_img_url_mobile
  const isMobile = useIsMobile()

  useShowLoginPopup({
    showWhen: checkBrPath,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  useEffect(() => {
    if (!isLoading) setShowPage(true)
  }, [isLoading])
  return (
    <div className="relative pt-[58px] lg:pt-[72px]">
      <div
        className={clsx(
          "absolute left-0 right-0 w-full bg-bg-1 z-1 overflow-hidden bg-transparent",
          isMobile
            ? "h-[738px] rounded-b-[10px] top-[0px] "
            : "min-h-[520px] aspect-[1410/520] top-[72px] rounded-[15px]"
        )}
      >
        {bgImg && <Image alt="index" src={bgImg} />}
      </div>
      {showPage ? (
        children
      ) : (
        <div className="h-[calc(100vh-170px)] flex items-center">
          <Spinner />
        </div>
      )}
    </div>
  )
}

WordlePageLayout.propTypes = {
  children: PropTypes.array,
  bg_img_url_desktop: PropTypes.string,
  bg_img_url_mobile: PropTypes.string,
}
