"use client"
import React, { useState } from "react"
import TextInput from "../text-input"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"

const PasswordHideIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Eye">
        <g id="Icon">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.6869 3.01957C13.8821 2.82431 13.8821 2.50772 13.6869 2.31246C13.4916 2.1172 13.175 2.1172 12.9798 2.31246L2.31311 12.9791C2.11785 13.1744 2.11785 13.491 2.31311 13.6862C2.50838 13.8815 2.82496 13.8815 3.02022 13.6862L4.91815 11.7883C5.82902 12.2171 6.87813 12.4993 8 12.4993C9.78934 12.4993 11.3936 11.7813 12.5471 10.896C13.1249 10.4525 13.6021 9.95772 13.9393 9.47192C14.2671 8.99954 14.5 8.48115 14.5 7.99935C14.5 7.51755 14.2671 6.99916 13.9393 6.52678C13.6021 6.04098 13.1249 5.5462 12.5471 5.1027C12.3723 4.96858 12.1872 4.8383 11.9927 4.71377L13.6869 3.01957ZM11.2664 5.4401L10.0858 6.62065C10.3476 7.01587 10.5 7.48981 10.5 7.99935C10.5 9.38006 9.38071 10.4993 8 10.4993C7.49046 10.4993 7.01652 10.3469 6.62131 10.0852L5.67683 11.0296C6.38718 11.319 7.17475 11.4993 8 11.4993C9.52437 11.4993 10.9201 10.8841 11.9382 10.1027C12.4461 9.71287 12.8476 9.29098 13.1177 8.90178C13.3971 8.49916 13.5 8.18422 13.5 7.99935C13.5 7.81448 13.3971 7.49954 13.1177 7.09692C12.8476 6.70772 12.4461 6.28583 11.9382 5.89599C11.7294 5.73575 11.5048 5.5825 11.2664 5.4401ZM7.35329 9.35316C7.54911 9.44687 7.76843 9.49935 8 9.49935C8.82843 9.49935 9.5 8.82778 9.5 7.99935C9.5 7.76778 9.44752 7.54846 9.35381 7.35264L7.35329 9.35316Z"
            // fill="#2C2A29"
            className="fill-icon-2"
          />
          <path
            d="M8 3.49935C8.64028 3.49935 9.25687 3.59129 9.83757 3.74994C10.0169 3.79894 10.0702 4.02204 9.93874 4.15351L9.51768 4.57457C9.45583 4.63642 9.36623 4.66107 9.28084 4.6421C8.86857 4.55053 8.43963 4.49935 8 4.49935C6.47564 4.49935 5.07986 5.11463 4.06178 5.89599C3.55385 6.28583 3.15238 6.70772 2.88228 7.09692C2.60287 7.49954 2.5 7.81448 2.5 7.99935C2.5 8.18422 2.60287 8.49916 2.88228 8.90178C3.10447 9.22195 3.41557 9.56424 3.8017 9.89261C3.91616 9.98995 3.92728 10.165 3.82103 10.2712L3.46802 10.6242C3.37771 10.7145 3.23351 10.7226 3.13647 10.6396C2.69977 10.2659 2.33418 9.86594 2.06074 9.47192C1.73291 8.99954 1.5 8.48115 1.5 7.99935C1.5 7.51755 1.73291 6.99916 2.06074 6.52678C2.39787 6.04098 2.87508 5.5462 3.45294 5.1027C4.60644 4.2174 6.21066 3.49935 8 3.49935Z"
            // fill="#2C2A29"
            className="fill-icon-2"
          />
          <path
            d="M8 5.49935C8.02745 5.49935 8.0548 5.49979 8.08204 5.50067C8.27964 5.50704 8.35173 5.74052 8.21193 5.88032L7.51159 6.58066C7.07605 6.73057 6.73122 7.0754 6.58131 7.51094L5.88097 8.21128C5.74117 8.35108 5.50769 8.27899 5.50132 8.08138C5.50044 8.05415 5.5 8.0268 5.5 7.99935C5.5 6.61864 6.61929 5.49935 8 5.49935Z"
            // fill="#2C2A29"
            className="fill-icon-2"
          />
        </g>
      </g>
    </svg>
  )
}

const PasswordShowIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z"
        // fill="#191919"
        className="fill-icon-2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z"
        // fill="#191919"
        className="fill-icon-2"
      />
    </svg>
  )
}
export default function PasswordInput({ ...props }) {
  const [isShowing, setIsShowing] = useState(false)

  const handleShowPassword = () => {
    setIsShowing((prevValue) => !prevValue)
  }

  const handleKeyDown = (e) => {
    // Todo: Add keydown event
  }

  return (
    <div className="relative">
      <TextInput type={isShowing ? "text" : "password"} {...props} />
      <button
        type="button"
        onClick={handleShowPassword}
        onKeyDown={handleKeyDown}
        className="absolute top-[13px] right-[10px] w-[16px] h-[16px]"
      >
        <div className="w-[16px] h-[16px]">
          {isShowing ? <PasswordShowIcon /> : <PasswordHideIcon />}
        </div>
      </button>
    </div>
  )
}

PasswordInput.propTypes = {
  props: PropTypes.object,
}