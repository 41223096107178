"use client"
import React, { useEffect, useRef, useState } from "react"
import EditHeader from "../edit-profile/edit-header"
import PopupOTP from "../change-phone/popup-otp"

import { data as staticData } from "../change-phone/datas/data"
import { data as otpSms, dataEmail as otpTelpon } from "../otp/datas/data"

import EditPhoneForm from "../edit-profile/edit-phone-form"
import useEditPhone from "@/hooks/use-edit-phone"
import { ClientApiList } from "@/lib/api/client-service"
import { usePathname } from "next/navigation"
import ReCAPTCHA from "react-google-recaptcha"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { Image } from "../shared/image/image"
import { ToastComponent } from "../toast"
import { capitalizeFirstLetter, urlAsset } from "@/helper/utils"
import FadeComponent from "../shared/fade-component/fade-component"

export function ChangePhone() {
  const captchaRef = useRef()
  const [isError, setIsError] = useState(false)
  const isMobile = useIsMobile()
  const pathName = usePathname()
  const [isSuccess, setIsSuccess] = useState(false)
  const [toastMessage, setToastMessage] = useState("Update nomor berhasil")
  const [isOpen, setIsOpen] = useState(false)
  const [isSms, setIsSms] = useState("true")
  const [step, setStep] = useState(1)
  const pageData = step === 1 ? staticData : isSms ? otpSms : otpTelpon
  const { register, getValues, onSubmit, watch, errors, setValue } =
    useEditPhone({
      setIsOpen,
      step,
      setStep,
      setIsSms,
      pathName,
      setIsSuccess,
      setToastMessage,
      setIsError,
      captchaRef,
    })

  const handleRequestOTP = async (resend = false) => {
    const response = await ClientApiList.requestOTP({
      phone: getValues("phone_number"),
      is_resend: resend,
    })
  }

  // useEffect(() => {
  //   console.log(captchaRef.current, 'rerender');
  //   captchaRef.current?.execute();
  // }, []);

  return (
    <>
      <ToastComponent
        onClick={() => {
          setToastMessage("Update nomor berhasil")
          setIsSuccess(false)
        }}
        title={toastMessage}
        desc={`Lanjut eksplor ${capitalizeFirstLetter(String(process.env.NEXT_PUBLIC_NAME))}.id sekarang!`}
        isOpen={isSuccess}
      />
      <PopupOTP
        isSms={isSms}
        isOpen={isOpen}
        setStep={setStep}
        setIsOpen={setIsOpen}
        handleRequestOTP={handleRequestOTP}
      />
      {/* <BackBanner
        className="h-[607px] top-[58px] lg:top-[72px]"
        bgImg={`/assets/amild/profile-edit/${isMobile ? "bg-mobile.jpg" : "bg-desktop.jpg"}`}
      /> */}
      {process.env.NEXT_PUBLIC_NAME === "marlboro" ? null :<Image
        className="absolute h-[100%] w-[100%]"
        alt="delete"
        src={`${urlAsset()}/profile-edit/${isMobile ? "bg-mobile.png" : "bg-desktop.png"}`}
      />}
      <FadeComponent delayIn={0.1}>
        <div className="py-[20px] lg:py-[32px] px-[10px] relative">
          <div className="relative z-[3] max-w-[462px] mx-auto">
            <EditHeader
              title={
                step === 1 && pathName.includes("verifikasi")
                  ? pageData.title_verify
                  : pageData.title
              }
              description={
                step === 1 && pathName.includes("verifikasi")
                  ? pageData.description_verify
                  : pageData.description
              }
            />
            <EditPhoneForm
              isError={isError}
              errors={errors}
              step={step}
              setStep={setStep}
              setIsSms={setIsSms}
              register={register}
              getValues={getValues}
              onSubmit={onSubmit}
              handleRequestOTP={handleRequestOTP}
              pathName={pathName}
              setIsOpen={setIsOpen}
              pageData={pageData}
              watch={watch}
            />
            {/* <div
            style={{
              overflow: 'hidden',
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              marginBottom: 8,
            }}
          >
        </div> */}
            <ReCAPTCHA
              ref={captchaRef}
              size="invisible"
              sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA}
              // onChange={(v) => {
              //   setValue('g-recaptcha-response', v);
              // }}
            />
          </div>
        </div>
      </FadeComponent>
    </>
  )
}
