import React from "react"
import PopupAction from "../shared/popup-action/popup-action"
import { Image } from "../shared/image/image"
import ButtonAction from "../shared/button-action/button-action"

const ReservationConfirmation = ({
  isOpen,
  handleSubmit,
  handleIsOpen,
  isLoading,
  setIsLoading,
}) => {
  const handleClose = () => {
    handleIsOpen(false)
  }

  const user = process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"

  return (
    <PopupAction
      isShowing={isOpen}
      href={`#`}
      onClickBtnX={() => handleClose()}
      zIndex="z-[20]"
    >
      <div className="max-w-[304px]">
        <div className="absolute rounded-[10px] overflow-hidden inset-0 z-[-1] "></div>
        <div className="absolute rounded-[10px] border-[3px] border-cta-3 border-dotted inset-[10px] z-[-1]"></div>
        <div className="min-w-[304px] min-h-[177px] bg-bg-1 rounded-[10px] overflow-hidden relative mb-[20px]">
          <Image
            data-testid="popup-image"
            alt="cover"
            fill
            style={{ objectFit: "cover", objectPosition: "center" }}
            className="absolute inset-0"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/reservation/confirmation.png`}
          />
        </div>
        <div
          data-testid="popup-title"
          className="text-[20px] font-black font-font-family-8 leading-[28px] mb-[10px] text-text-2"
        >
          Konfirmasi RSVP
        </div>
        <div
          data-testid="popup-description"
          className="text-[16px] text-text-2 md:text-[16px] font-medium font-font-family-6 mb-[20px] leading-[24px] md:leading-[25px]"
        >
          Pastikan data yang akan {user} kirim udah benar.{" "}
          {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} ga bisa{" "}
          <i>ngedit</i> ulang data yang udah {user} kirim.
        </div>
        <div className="flex items-center gap-[5px]">
          <ButtonAction
            onClick={() => {
              handleClose()
            }}
            className=" mb-[10px]"
            intent={isLoading ? "secondary_disable" : "secondary"}
            disabled={isLoading}
          >
            Cek Lagi
          </ButtonAction>
          <ButtonAction
            onClick={() => {
              handleSubmit()
            }}
            className=" mb-[10px]"
            intent={isLoading ? "primary_disable" : "primary"}
            disabled={isLoading}
          >
            Kirim
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}

export default ReservationConfirmation
