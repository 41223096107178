"use client";
import PropTypes from "prop-types";
import { Description } from "../shared/description/description";
import clsx from "clsx";
import { useIsDesktop, useIsMobile } from "@/hooks/use-is-mobile";
import { Suspense, useEffect, useRef, useState } from "react";
import ButtonAction from "../shared/button-action/button-action";
import { Image } from "../shared/image/image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import dynamic from "next/dynamic";
import { Spinner } from "../shared/spinner";
import { ClientApiList } from "@/lib/api/client-service";
import { useWindowSize } from "@/hooks/use-window-size";
import PopupAction from "../shared/popup-action/popup-action";
import { cn } from "@/lib/utils";
import { ToastInfo } from "../toast/toast-info";
import { useSearchParams } from "next/navigation";
import { SwiperComp } from "../swiper/swiper";
import UseQueryParams from "@/hooks/use-query-params";
import MusicCard from "../submission/music-card/music-card";
import UpvoteButton from "../submission/upvote-button/upvote-button";
import { toProxy } from "@/helper/utils";
import PreviewAudio from "../submission/audio-submission/components/preview-audio";

const DynamicVideoJs = dynamic(
  () => import("@/lib/components/video-js/video-js"),
  {
    ssr: false,
  }
);

const dummy = "/assets/dummy-video.mp4";

const PosterVoteSection = ({
  date,
  format,
  total,
  voted,
  uuid,
  isShowing,
  fullname,
  setDataPoster,
  dataPoster,
  setSelectedPoster,
}) => {
  // const [vote, setVote] = useState(voted)
  // const [count, setCount] = useState(total || 0)
  const [onLoad, setOnLoad] = useState(false);
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  /* posted date */
  function daysBetween(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    return diffDays;
  }

  /* Target date (change this to your desired date) */
  const targetDate = new Date(date);

  /* Calculate days since target date*/
  const currentDate = new Date();
  const days = daysBetween(currentDate, targetDate);

  const onVote = async () => {
    setOnLoad(true);
    try {
      const { data } = await ClientApiList.getGalleryVote({
        vote: voted === 0 ? "vote" : "unvote",
        submission_uuid: uuid,
        type: "ugc",
      });
      if (data) {
        setOnLoad(false);
        setDataPoster(
          dataPoster.map((poster) => {
            if (poster.uuid === uuid) {
              setSelectedPoster({
                ...poster,
                voted: voted === 0 ? 1 : 0,
                total_vote: voted === 0 ? total + 1 : total - 1,
              });
              return {
                ...poster,
                voted: voted === 0 ? 1 : 0,
                total_vote: voted === 0 ? total + 1 : total - 1,
              };
            }
            return poster;
          })
        );
      }
    } catch (err) {
      setOnLoad(false);
    }
  };

  const bgBtn = () => {
    if (voted === 1) return "var(--cta_3)";
    else if (voted === 0) return "var(--bg_1)";
  };

  return (
    <div
      className={cn(
        "flex items-center justify-between mx-[5px]",
        isShowing ? "mb-[0]" : "mb-[5px]"
      )}
    >
      {isShowing ? (
        <div className="text-[12px] leading-[16px] tracking-[0.25px] text-text-3">
          {" "}
          {days === 0
            ? "Today"
            : `Posted ${days} day${days > 1 ? "s" : ""} ago`}
        </div>
      ) : null}
      <div
        className={cn(
          "font-medium text-text-3 text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px] capitalize font-bold font-font-family-7",
          isShowing ? "hidden" : ""
        )}
      >
        {fullname}
      </div>
      <UpvoteButton
        className={cn(
          isShowing
            ? " p-[10px] min-w-[107px] justify-center border-[1px] border-cta-4 rounded-[20px] duration-300"
            : "",
          "font-medium flex items-center gap-[5px]",
          voted === 1 ? "text-text-1" : "text-text-2 hover:text-text-1"
          // vote ? "bg-[#FDE8E9] text-cta-1" : "text-text-3",
        )}
        onClick={onVote}
        onLoad={onLoad}
        setOnMouseEnter={onMouseEnter}
        style={{ background: isShowing ? bgBtn() : "transparent" }}
        vote={voted}
        onMouseEnter={onMouseEnter}
      >
        <span>
          {isShowing ? "Upvote∙" : ""}
          {total || 0}
        </span>
      </UpvoteButton>
      {/* <button
        onClick={onVote}
        disabled={onLoad}
        className={cn(
          isShowing
            ? " p-[10px] min-w-[107px] justify-center border-[1px] border-cta-4 rounded-[20px] duration-300"
            : "",
          "font-medium flex items-center gap-[5px]",
          voted === 1 ? "text-text-1" : "text-text-2 hover:text-text-1"
          // vote ? "bg-[#FDE8E9] text-cta-1" : "text-text-3",
        )}
        onMouseEnter={() => setOnMouseEnter(true)}
        onMouseLeave={() => setOnMouseEnter(false)}
        style={{ background: isShowing ? bgBtn() : "transparent" }}
      >
        {voted || onMouseEnter ? (
          <UpvoteIconFill fillColor={"fill-icon-1"} />
        ) : (
          <UpvoteIcon fillColor={"fill-icon-1"} />
        )}
        <span>
          {isShowing ? "Upvote∙" : ""}
          {total || 0}
        </span>
      </button> */}
    </div>
  );
};

const VoteSection = ({ date, format, total, voted, uuid }) => {
  const [vote, setVote] = useState(voted);
  const [count, setCount] = useState(total || 0);
  const [onLoad, setOnLoad] = useState(false);
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  /* posted date */
  function daysBetween(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    return diffDays;
  }

  /* Target date (change this to your desired date) */
  const targetDate = new Date(date);

  /* Calculate days since target date*/
  const currentDate = new Date();
  const days = daysBetween(currentDate, targetDate);

  const handleFormat = (type) => {
    switch (type) {
      case "galeri foto":
        return "image";
      case "galeri video":
        return "video";
      case "galeri jawaban":
        return "text";
    }
  };

  const onVote = async () => {
    setOnLoad(true);

    const { data } = await ClientApiList.getGalleryVote({
      vote: vote === 0 ? "vote" : "unvote",
      submission_uuid: uuid,
      type: handleFormat(format),
    });

    if (data) {
      setVote(vote === 0 ? 1 : 0);
      setCount(vote === 0 ? count + 1 : count - 1);
      setOnLoad(false);
    }
  };

  const bgBtn = () => {
    if (vote === 1) return "var(--cta_3)";
    else if (vote === 0) return "var(--bg_1)";
  };

  return (
    <div className="relative px-[15px] mb-[14px] text-xs font-font-family-6 text-text-2 flex justify-between items-center">
      {format !== "galeri jawaban" && (
        <p className="text-text-3">
          {days === 0
            ? "Today"
            : `Posted ${days} day${days > 1 ? "s" : ""} ago`}
        </p>
      )}
      <UpvoteButton
        onClick={onVote}
        style={{ background: bgBtn() }}
        className={clsx(
          "relative flex justify-center items-center p-[10px] rounded-[100px]  hover:text-text-1 duration-300",
          vote === 1 ? "text-text-1" : "text-text-2 border border-cta-4",
          onLoad ? "cursor-wait" : "cursor-pointer"
        )}
        onLoad={onLoad}
        setOnMouseEnter={setOnMouseEnter}
        vote={vote}
        onMouseEnter={onMouseEnter}
      >
        <span className="mr-[2px] ml-[5px]">Upvote</span>
        <DotIcon fillColor={vote ? "fill-text-1" : "fill-text-2"} />
        <span className="ml-[2px]">{count}</span>
      </UpvoteButton>
      {/* <button
        onClick={() => onVote()}
        style={{ background: bgBtn() }}
        className={clsx(
          "relative flex justify-center items-center p-[10px] rounded-[100px]  hover:text-text-1 duration-300",
          vote === 1 ? "text-text-1" : "text-text-2 border border-cta-4",
          onLoad ? "cursor-wait" : "cursor-pointer"
        )}
        disabled={onLoad}
        onMouseEnter={() => setOnMouseEnter(true)}
        onMouseLeave={() => setOnMouseEnter(false)}
      >
        {vote || onMouseEnter ? (
          <UpvoteIconFill fillColor={"fill-icon-1"} />
        ) : (
          <UpvoteIcon fillColor={"fill-icon-1"} />
        )}
        <span className="mr-[2px] ml-[5px]">Upvote</span>
        <DotIcon fillColor={vote ? "fill-text-1" : "fill-text-2"} />
        <span className="ml-[2px]">{count}</span>
      </button> */}
    </div>
  );
};

VoteSection.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string,
  total: PropTypes.number,
  voted: PropTypes.bool,
  uuid: PropTypes.string,
};

const TextGallery = ({ item }) => {
  const isMobile = useIsMobile();
  /* posted date */
  function daysBetween(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    return diffDays;
  }

  /* Target date (change this to your desired date) */
  const targetDate = new Date(item?.approval_date);

  /* Calculate days since target date*/
  const currentDate = new Date();
  const days = daysBetween(currentDate, targetDate);

  return (
    <div
      className={clsx(
        "mt-[20px] mb-[25px] text-sm",
        isMobile ? "px-[15px]" : "px-[20px]"
      )}
    >
      <div className="flex justify-between gap-[5px] mb-[10px]">
        <div className="flex items-center gap-[5px]">
          <p className="font-font-family-7 font bold">{item?.fullname}</p>
          {days === 0 ? (
            <p className="text-text-3 font-font-family-6">Today</p>
          ) : (
            <p className="text-text-3 font-font-family-6">
              {days} day{days > 1 && "s"} ago
            </p>
          )}
        </div>
        <TopVoted is_winner={!!item?.is_winner} formatText />
      </div>
      <p>{item?.answer}</p>
    </div>
  );
};

TextGallery.propTypes = {
  item: PropTypes.object,
};

const TextSection = ({ item, format }) => {
  const [textList, setTextList] = useState(item);
  const [nextPageTxt, setNextPageTxt] = useState(0);
  const [load, setLoad] = useState(false);

  const isMobile = useIsMobile();

  return (
    <div className="w-full rounded-[15px] overflow-hidden border border-cta-4 bg-bg-3">
      <p
        className={clsx(
          "text-base font-bold font-font-family-7 mt-[30px] ",
          isMobile ? " px-[15px]" : " px-[20px]"
        )}
      >
        “{item?.question}”
      </p>
      {item?.answer?.map((text, index) => (
        <div key={index}>
          <TextGallery item={text} />
          <VoteSection
            format={format}
            total={text?.total_vote}
            date={text?.approval_date}
            voted={text?.voted}
            uuid={text?.submission_uuid}
          />
          {index !== item.answer.length - 1 && (
            <div className="mx-[17px] h-[2px] max-w-[655px] w-[calc(100vw-55px)] bg-[#F5F5F5]" />
          )}
        </div>
      ))}
      {/* {nextPageTxt && !load && (
        <div className="w-full flex justify-center mt-[16px] mb-[20px]">
          <button
            className="text-text-1 font-font-family-7 font-bold underline"
            onClick={() => loadMore()}
          >
            Show More
          </button>
        </div>
      )} */}
      {load && (
        <div className="w-full flex justify-center my-[30px]">
          <Spinner />
        </div>
      )}
    </div>
  );
};

TextSection.propTypes = {
  item: PropTypes.object,
  format: PropTypes.string,
};

const DurationVideo = ({ duration }) => {
  return (
    <div className="p-[5px] absolute z-[3] top-[10px] left-[20px] text-white font-medium font-font-family-6 text-[10px] leading-[10px] bg-[#616262] bg-opacity-25 rounded-[5px] flex justify-center items-center">
      {duration}
    </div>
  );
};

DurationVideo.propTypes = {
  duration: PropTypes.string,
};

const CardFile = ({ item, format }) => {
  const isMobile = useIsMobile();
  const swiperRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);

  const { width } = useWindowSize();
  const isDesktop = width >= 1024;

  useEffect(() => {
    if (format) {
      swiperRef.current;
    }
  }, [format]);

  const Dots = ({ items }) => {
    return (
      <div
        className={clsx(
          "absolute z-[3] bottom-[5px] left-2/4 -translate-x-1/2 flex gap-2 bg-[rgba(97,98,98,0.30)] p-[5px] rounded-[5px]"
        )}
      >
        {items?.map((_, index) => (
          <div
            key={index}
            data-testid={`dots-${index}`}
            className={clsx(
              "w-[5px] h-[5px] bg-bg-3 rounded-full",
              index != activeIndex && "opacity-20",
              !isMobile && "w-[7px] h-[7px]",
              index === activeIndex && isMobile && "w-[20px]",
              index === activeIndex && !isMobile && "w-[28px]"
            )}
          />
        ))}
      </div>
    );
  };

  const nextButton = (
    <button
      data-testid="next-btn"
      onClick={() => {
        swiperRef.current?.slideNext();
      }}
      className={clsx(
        "absolute z-10 -translate-y-1/2 right-[25px] top-1/2",
        isDesktop && "opacity-0 group-hover:opacity-100"
      )}
    >
      <IconNextSGV />
    </button>
  );

  const prevButton = (
    <button
      data-testid="prev-btn"
      onClick={() => {
        swiperRef.current?.slidePrev();
      }}
      className={clsx(
        "absolute z-10 -translate-y-1/2 left-[25px] top-1/2",
        isDesktop && "opacity-0 group-hover:opacity-100"
      )}
    >
      <IconPrevSGV />
    </button>
  );

  const itemLength =
    (format === "galeri foto" && item?.image_file?.length) ||
    (format === "galeri video" && item?.video_file?.length);

  return (
    <div className="relative w-full group">
      <Swiper
        style={{
          overflow: "hidden",
          aspectRatio: "355/270",
          position: "relative",
          margin: "10px",
          borderRadius: "15px",
        }}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          setActiveIndex(swiper.activeIndex);
        }}
        breakpoints={{
          1152: {
            allowTouchMove: false,
          },
        }}
      >
        {format === "galeri foto"
          ? item?.image_file?.map((file) => (
              <SwiperSlide key={file} style={{ width: "fit-content" }}>
                <Image
                  className="absolute overflow-hidden"
                  alt="cover-img"
                  src={file}
                  // src={"https://dummyimage.com/300.png/09f/fff"}
                />
              </SwiperSlide>
            ))
          : item?.video_file?.map((file, index) => (
              <SwiperSlide key={file} style={{ width: "fit-content" }}>
                <DynamicVideoJs
                  fill
                  poster={item?.video_thumb[index]}
                  skin="vjs-amild-skin-small-square"
                  src={file}
                  // src={dummy}
                  duration={item?.video_duration[index]}
                  branded={"Yes"}
                  category={item?.category}
                  campaign={item?.campaign}
                  title={item?.question}
                  videoId={item?.uuid}
                  publishDate={item?.approval_date}
                  isRegular={1}
                  aspectRatio="4:3"
                  preload="metadata"
                />
                <DurationVideo duration={item?.video_duration[index]} />
              </SwiperSlide>
            ))}
      </Swiper>
      {format === "galeri foto" && item?.image_file?.length > 1 && (
        <Dots items={item?.image_file} />
      )}
      {format === "galeri video" && item?.video_file?.length > 1 && (
        <Dots items={item?.video_file} />
      )}
      {activeIndex !== 0 && prevButton}
      {activeIndex + 1 !== itemLength && nextButton}
    </div>
  );
};

const PosterCard = ({
  item,
  index,
  handleClick,
  isShowing,
  setDataPoster,
  dataPoster,
  setSelectedPoster,
}) => {
  return (
    <div
      tabIndex={0}
      role="button"
      key={index}
      className={clsx(
        "relative rounded-[5px] overflow-hidden border border-cta-4 bg-bg-3 w-full w-[100%] !h-fit p-[4px] md:p-[5.42px]",
        isShowing ? "p-[9px] lg:pb-[20px]" : "cursor-pointer max-w-[221px]"
      )}
    >
      <div
        className={cn(
          " mb-[5px] w-full rounded-[4px]",
          isShowing ? "min-h-[460px] " : "min-h-[238px] md:min-h-[305px]"
        )}
        onClick={(e) => {
          handleClick();
          e.stopPropagation();
        }}
      >
        <Image
          className="w-full h-full"
          src={item.image_file[0]}
          alt="galeri poster"
        />
      </div>
      {isShowing ? (
        <div className="mx-0 mt-[20px] mb-[10px] px-[5px] text-[16px] leading-[24px] text-left font-bold font-font-family-7 flex flex-col gap-y-[5px]">
          <TopVoted is_winner={!!item?.is_winner} />
          <span className="capitalize font-bold font-font-family-7">
            {item.fullname}
          </span>
        </div>
      ) : null}
      <div
        className={cn(
          isShowing
            ? "mx-0 mb-[20px] px-[5px] text-[16px] leading-[24px] text-left"
            : "mt-[10px] mb-[5px] md:mb-[5px] px-[5px] text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px]",
          "flex flex-col gap-y-[5px]"
        )}
      >
        {!isShowing ? <TopVoted is_winner={!!item?.is_winner} /> : null}
        <p
          className={cn(
            "break-all capitalize",
            isShowing ? "text-text-3" : "",
            item.caption ? "block" : "hidden"
          )}
        >
          {item?.caption}
        </p>
      </div>
      <PosterVoteSection
        total={item?.total_vote}
        date={item?.approval_date}
        voted={item?.voted}
        uuid={item?.uuid}
        fullname={item?.fullname}
        isShowing={isShowing}
        setDataPoster={setDataPoster}
        dataPoster={dataPoster}
        setSelectedPoster={setSelectedPoster}
      />
    </div>
  );
};

const Layout = dynamic(() => import("react-masonry-list"), {
  ssr: false,
});

export function GallerySubmissionFallback() {
  return (
    <div className="w-full h-full">
      <Spinner />
    </div>
  );
}

function SuspendedGallerySubmission({ title, subtitle, media_type }) {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const medias = [
    ...media_type,
  ]
  const swiperRef = useRef(null)
  const searchParams = useSearchParams()
  const [loadingData, setLoadingData] = useState(false)
  const [format, setFormat] = useState()
  const [dataImg, setDataImg] = useState([])
  const [dataVid, setDataVid] = useState([])
  const [dataTxt, setDataTxt] = useState([])
  const [dataPoster, setDataPoster] = useState([])
  const [dataMusic, setDataMusic] = useState([])
  const [selectedPoster, setSelectedPoster] = useState({})
  const [selectedAudio, setSelectedAudio] = useState({})
  const [isShowingPoster, setIsShowingPoster] = useState(false)
  const [pagingData, setPagingData] = useState(null)
  const [isToasting, setIsToasting] = useState(false)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const init = useRef(true)
  const _LENGTH = 10

  const { handlePushQuery, handleManipulateQuery } = UseQueryParams();

  const getImgGallery = async (page, length = _LENGTH, stat) => {
    setLoadingData(true)
    setPagingData(null)
    const { data } = await ClientApiList.getGallerySubmission({
      length: length,
      page: page,
      type: "image",
    });
    if (data) {
      let paging = {
        ...data?.data?.data?.paging,
      }
      if (stat && searchParams.has("page")) {
        let checkPage = parseInt(searchParams.get("page")) + 1
        paging = {
          currPage: parseInt(searchParams.get("page")),
          firstPage: 0,
          lastPage: Math.floor(data?.data?.data?.paging.totalData / _LENGTH),
          nextPage:
            checkPage > Math.floor(data?.data?.data?.paging.totalData / _LENGTH)
              ? Math.floor(data?.data?.data?.paging.totalData / _LENGTH)
              : checkPage,
          prevPage: parseInt(searchParams.get("page")) - 1,
          totalData: data?.data?.data?.paging.totalData,
        }
      }
      setDataImg([...dataImg, ...data.data.data.items])
      setPagingData(paging)
      setLoadingData(false)
    }
  };

  const getPosterGallery = async (page, length = _LENGTH, stat) => {
    setLoadingData(true)
    setPagingData(null)

    const { data } = await ClientApiList.getGallerySubmission({
      length: length,
      page: page,
      type: "ugc",
    });
    if (data) {
      let paging = {
        ...data?.data?.data?.paging,
      }
      if (stat && searchParams.has("page")) {
        let checkPage = parseInt(searchParams.get("page")) + 1
        paging = {
          currPage: parseInt(searchParams.get("page")),
          firstPage: 0,
          lastPage: Math.floor(data?.data?.data?.paging.totalData / _LENGTH),
          nextPage:
            checkPage > Math.floor(data?.data?.data?.paging.totalData / _LENGTH)
              ? Math.floor(data?.data?.data?.paging.totalData / _LENGTH)
              : checkPage,
          prevPage: parseInt(searchParams.get("page")) - 1,
          totalData: data?.data?.data?.paging.totalData,
        }
      }
      setDataPoster([...dataPoster, ...data.data.data.items])
      setPagingData(paging)
      setLoadingData(false)
    }
  };

  const getMusicGallery = async (page, length = _LENGTH, stat) => {
    setLoadingData(true)
    setPagingData(null)
    const { data } = await ClientApiList.getGalleryAudio({
      length: length,
      page: page,
      type: "audio",
    });
    if (data) {
      let paging = {
        ...data?.data?.data?.paging,
      }
      if (stat && searchParams.has("page")) {
        let checkPage = parseInt(searchParams.get("page")) + 1
        paging = {
          currPage: parseInt(searchParams.get("page")),
          firstPage: 0,
          lastPage: Math.floor(data?.data?.data?.paging.totalData / _LENGTH),
          nextPage:
            checkPage > Math.floor(data?.data?.data?.paging.totalData / _LENGTH)
              ? Math.floor(data?.data?.data?.paging.totalData / _LENGTH)
              : checkPage,
          prevPage: parseInt(searchParams.get("page")) - 1,
          totalData: data?.data?.data?.paging.totalData,
        }
      }
      setDataMusic([...dataMusic, ...data.data.data.items])
      setPagingData(paging)
      setLoadingData(false)
    }
  };

  const getVidGallery = async (page, length = _LENGTH, stat) => {
    setLoadingData(true)
    setPagingData(null)
    const { data } = await ClientApiList.getGallerySubmission({
      length: length,
      page: page,
      type: "video",
    });
    if (data) {
      let paging = {
        ...data?.data?.data?.paging,
      }
      if (stat && searchParams.has("page")) {
        let checkPage = parseInt(searchParams.get("page")) + 1
        paging = {
          currPage: parseInt(searchParams.get("page")),
          firstPage: 0,
          lastPage: Math.floor(data?.data?.data?.paging.totalData / _LENGTH),
          nextPage:
            checkPage > Math.floor(data?.data?.data?.paging.totalData / _LENGTH)
              ? Math.floor(data?.data?.data?.paging.totalData / _LENGTH)
              : checkPage,
          prevPage: parseInt(searchParams.get("page")) - 1,
          totalData: data?.data?.data?.paging.totalData,
        }
      }
      setDataVid([...dataVid, ...data.data.data.items])
      setPagingData(paging)
      setLoadingData(false)
    }
  };

  const getTxtGallery = async () => {
    setLoadingData(true);
    setPagingData(null);
    const { data } = await ClientApiList.getGalleryText();
    if (data) {
      setPagingData(null);
      setDataTxt(data?.data?.data?.list);
      setLoadingData(false);
    }
  };

  const handleTabId = (id) => {
    switch (id) {
      case 0:
        return "galeri foto";
      case 1:
        return "galeri video";
      case 2:
        return "galeri jawaban";
      case 3:
        return "galeri poster";
      default:
        return "galeri foto";
    }
  };

  const handleFetch = () => {
    const format = searchParams.has("tab")
      ? String(searchParams.get("tab")).toLowerCase()
      : handleTabId(medias[0]?.id)
    const page =
      !init.current && searchParams.has("page") ? searchParams.get("page") : 0
    const length =
      init.current && searchParams.has("page")
        ? parseInt(_LENGTH) * (parseInt(searchParams.get("page")) + 1)
        : _LENGTH

    if (format === "galeri foto") {
      getImgGallery(page, length, init.current)
      setDataVid([])
      setDataTxt([])
      setDataPoster([])
      setDataMusic([])
    } else if (format === "galeri video") {
      getVidGallery(page, length, init.current)
      setDataImg([])
      setDataTxt([])
      setDataPoster([])
      setDataMusic([])
    } else if (format === "galeri jawaban") {
      getTxtGallery(page, length, init.current)
      setDataImg([])
      setDataVid([])
      setDataPoster([])
      setDataMusic([])
    } else if (format === "galeri poster") {
      getPosterGallery(page, length, init.current)
      setDataImg([])
      setDataVid([])
      setDataTxt([])
      setDataMusic([])
    } else if (format === "galeri suara") {
      getMusicGallery(page, length, init.current)
      setDataImg([])
      setDataVid([])
      setDataTxt([])
      setDataPoster([])
    }
    init.current = false
  }

  useEffect(() => {
    setIsToasting(
      String(searchParams.get("tab")).toLowerCase() === "galeri poster" &&
        searchParams.get("showTab")
    );

    setFormat(
      searchParams.has("tab")
        ? String(searchParams.get("tab")).toLowerCase()
        : handleTabId(medias[0]?.id)
    );

    if (swiperRef.current) {
      swiperRef.current.slideTo(
        searchParams.has("tab")
          ? medias.find((media) => media.label === searchParams.get("tab")).id
          : medias[0]?.id
      );
    }

    handleFetch();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchParams]);

  const dataGallery =
    medias.length >= 1 ? (
      <div
        className={clsx(
          "relative flex flex-col items-center mb-[20px]",
          isMobile
            ? format === "galeri poster"
              ? "mx-0"
              : ""
            : "max-w-[1280px] w-fit mx-auto"
        )}
      >
        <div
          className={clsx(
            "relative mt-[20px] w-[100%]",
            isMobile
              ? "flex flex-col gap-[10px] mx-[10px]"
              : "flex flex-wrap basis-[calc((100%/3)-10px)] gap-x-[10px] gap-y-[20px] justify-left",
            isMobile
              ? format === "galeri poster"
                ? "mx-0"
                : "mx-[10px]"
              : format === "galeri poster"
                ? "!mt-[0]"
                : ""
          )}
        >
          {format === "galeri foto" &&
            dataImg?.map((item, index) => (
              <div
                key={index}
                className={clsx(
                  "rounded-[15px] overflow-hidden border border-cta-4 bg-bg-3",
                  isMobile
                    ? "w-[calc(100vw-20px)]"
                    : "w-[calc((100vw/3)-10px)] max-w-[420px]"
                )}
              >
                <CardFile item={item} format={format} />
                <div className="mt-[20px] mb-[25px] px-[15px] text-sm">
                  <div className="mb-[10px] flex flex-wrap gap-x-[10px] gap-y-[5px] items-center">
                    <TopVoted is_winner={!!item?.is_winner} />
                    <p className="font-font-family-7 font bold">
                      {item?.fullname}
                    </p>
                  </div>
                  <p>{item?.caption}</p>
                </div>
                <VoteSection
                  format={format}
                  total={item?.total_vote}
                  date={item?.approval_date}
                  voted={item?.voted}
                  uuid={item?.uuid}
                />
              </div>
            ))}

          {format === "galeri suara" &&
            dataMusic?.map((item, index) => (
              <div
                key={index}
                className={clsx(
                  "rounded-[15px] overflow-hidden border border-cta-4 bg-bg-3",
                  isMobile
                    ? "w-[calc(100vw-20px)]"
                    : "w-[calc((100vw/3)-10px)] max-w-[420px]"
                )}
              >
                <MusicCard
                  vote={item.voted}
                  dataAudio={dataMusic}
                  setSelectedAudio={setSelectedAudio}
                  setDataMusic={setDataMusic}
                  item={item}
                  setIsPreviewOpen={setIsPreviewOpen}
                />
              </div>
            ))}

          {format === "galeri poster" && (
            <Layout
              minWidth={isDesktop ? 222 : 173}
              colCount={isDesktop ? 4 : !isMobile ? 3 : 2}
              items={dataPoster?.map((item, index) => (
                <PosterCard
                  key={index}
                  index={index}
                  item={item}
                  setDataPoster={setDataPoster}
                  dataPoster={dataPoster}
                  setSelectedPoster={setSelectedPoster}
                  handleClick={() => {
                    setSelectedPoster(item);
                    setIsShowingPoster(true);
                  }}
                />
              ))}
            ></Layout>
          )}

          {format === "galeri video" &&
            dataVid?.map((itemVid, index) => (
              <div
                key={index}
                className={clsx(
                  "rounded-[15px] overflow-hidden border border-cta-4 bg-bg-3",
                  isMobile
                    ? "w-[calc(100vw-20px)]"
                    : "w-[calc((100vw/3)-10px)] max-w-[420px]"
                )}
              >
                <CardFile item={itemVid} format={format} />
                <div className="mt-[20px] mb-[25px] px-[15px] text-sm">
                  <div className="mb-[10px] flex flex-wrap gap-x-[10px] gap-y-[5px] items-center">
                    <TopVoted is_winner={!!itemVid?.is_winner} />
                    <p className="font-font-family-7 font bold">
                      {itemVid?.fullname}
                    </p>
                  </div>
                  <p>{itemVid?.caption}</p>
                </div>
                <VoteSection
                  format={format}
                  total={itemVid?.total_vote}
                  date={itemVid?.approval_date}
                  voted={itemVid?.voted}
                  uuid={itemVid?.uuid}
                />
              </div>
            ))}
        </div>
      </div>
    ) : (
      <p className="text-text-3 text-center">Tidak Ada Data</p>
    );

  if (!title) {
    return (
      <div className="w-full h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "w-full h-full relative pt-[20px] lg:pt-[59.6px] text-text-2",
        isMobile && "px-[10px]"
      )}
    >
      <div></div>
      <h2
        className={clsx(
          "font-bold text-xl lg:text-[40px] lg:leading-[44px]",
          isMobile
            ? "text-left capitalize font-font-family-7"
            : "text-center uppercase font-font-family-3"
        )}
      >
        {title}
      </h2>
      <Description
        className={clsx(
          "mb-[60px] mt-[15px] text-base lg:text-[24px] lg:leading-[32px]",
          !isMobile && "text-center"
        )}
        description={subtitle}
      />
      {/* <div className="max-w-[100%] overflow-hidden">
        <div className="flex justify-center gap-[5px] w-fit max-w-[100%] overflow-scroll">

        </div>
      </div> */}
      <div>
        <SwiperComp
          ref={swiperRef}
          className="md:max-w-fit"
          slidesPerView={"auto"}
          spaceBetween={isMobile ? 5 : 10}
        >
          {medias?.map((item, index) => (
            <SwiperSlide className="!w-fit" key={index}>
              <ButtonAction
                key={item?.id}
                intent={
                  !searchParams.has("tab") && index === 0
                    ? "primary"
                    : String(searchParams.get("tab")).toLowerCase() ===
                        String(item.label).toLowerCase()
                      ? "primary"
                      : "secondary_disable"
                }
                className={clsx("!p-[10px] !py-[11px] !text-sm grow-[1]")}
                isMax={true}
                onClick={() => {
                  handleManipulateQuery("tab", item.label, "page", false);
                }}
              >
                {item.label}
              </ButtonAction>
            </SwiperSlide>
          ))}
        </SwiperComp>
      </div>
      {format === "galeri poster" ? (
        <div className="my-[15px] relative lg:my-[30px] w-[full] max-w-[932px] h-[100dvh] rounded-[10px] max-h-[266.5px] md:max-h-[210px] md:mx-auto">
          <Image
            className="absolute inset-0"
            alt="intro"
            src={
              isDesktop
                ? "/assets/amild/ugc/intro-desktop.png"
                : "/assets/amild/ugc/intro-mobile.png"
            }
          />
        </div>
      ) : null}
      {format === "galeri jawaban" ? (
        <div
          className={clsx(
            "mt-[20px] flex flex-col gap-[10px] justify-center max-w-[697px] w-fit mx-auto mb-[20px]",
            !isMobile && "min-w-[697px]"
          )}
        >
          {dataTxt?.map((item, index) => (
            <div key={index}>
              <TextSection item={item} format={format} />
            </div>
          ))}
        </div>
      ) : (
        dataGallery
      )}
      {loadingData && (
        <div className="w-full flex justify-center my-[30px]">
          <Spinner />
        </div>
      )}
      {pagingData && pagingData?.currPage !== pagingData?.lastPage ? (
        <div className="bottom-0 mt-[30px] max-w-[355px] mx-auto mt-[20px]">
          <ButtonAction
            onClick={() => {
              setLoadingData(true);
              handlePushQuery(false, ["page", pagingData.currPage + 1]);
            }}
            intent={"secondary"}
          >
            Muat Lebih Banyak
          </ButtonAction>
        </div>
      ) : null}

      {/* PREVIEW AUDIO */}
      <PreviewAudio
        open={isPreviewOpen}
        close={() => setIsPreviewOpen(false)}
        userName={selectedAudio?.fullname}
        isGallery={true}
        setSelectedAudio={setSelectedAudio}
        setDataMusic={setDataMusic}
        dataAudio={dataMusic}
        selectedAudio={selectedAudio}
        data={{
          audio: [{ preview: toProxy(selectedAudio?.audio_file) }],
          cover: toProxy(selectedAudio?.cover_img),
          band_logo: selectedAudio?.band_profile?.band_logo?.length
            ? [
                {
                  preview: toProxy(selectedAudio?.band_profile?.band_logo),
                  isLink: true,
                },
              ]
            : [],
          official_press_photo: selectedAudio?.band_image?.map((file) => ({
            preview: toProxy(file),
            isLink: true,
          })),
        }}
      />

      <PopupAction
        zIndex={"z-[20]"}
        isShowing={isShowingPoster}
        href={"#"}
        showCloseBtn={true}
        maxWidth="max-w-[335px]"
        className="p-0"
        onClickBtnX={() => setIsShowingPoster(false)}
        isScroll={false}
      >
        <div className="max-h-[calc(100dvh-150px)] overflow-auto rounded-[5px] relative z-[21]">
          <PosterCard
            setDataPoster={setDataPoster}
            dataPoster={dataPoster}
            setSelectedPoster={setSelectedPoster}
            item={selectedPoster}
            isShowing={true}
          />
        </div>
      </PopupAction>
      <ToastInfo
        onClick={() => {
          setIsToasting(false);
        }}
        title={`Ayo Lanjut Bikin Karya ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"}!`}
        desc={`Udah nemu inspirasi? Ayo lanjut bikin karya  ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} biar progres  ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} ga ilang!`}
        isOpen={isToasting}
      />
    </div>
  );
}

export function GallerySubmission(props) {
  return (
    <Suspense fallback={GallerySubmissionFallback}>
      <SuspendedGallerySubmission {...props} />
    </Suspense>
  );
}

GallerySubmission.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  media_type: PropTypes.array,
};

const UpvoteIcon = ({ fillColor }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5204 2.05195C8.2664 1.73529 7.73306 1.73529 7.47906 2.05195L2.14573 8.71862C1.98573 8.91929 1.95506 9.19329 2.06573 9.42462C2.17706 9.65462 2.40973 9.80195 2.6664 9.80195H3.99973H5.33306V11.802V14.4686C5.33306 14.8373 5.63106 15.1353 5.99973 15.1353H9.99973C10.3684 15.1353 10.6664 14.8373 10.6664 14.4686V11.1353V9.80195H11.9997H13.3331C13.5897 9.80195 13.8224 9.65462 13.9337 9.42462C14.0444 9.19329 14.0137 8.91929 13.8537 8.71862L8.5204 2.05195ZM9.99973 8.46862H9.33306V9.13529V11.1353V13.802H6.6664V11.802V9.13529V8.46862H5.99973H4.05373L7.99973 3.53595L11.9457 8.46862H9.99973Z"
        // fill="#ED1C24"
        className={fillColor}
      />
    </svg>
  );
};

UpvoteIcon.propTypes = {
  fillColor: PropTypes.string,
};

const UpvoteIconFill = ({ fillColor }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.6664 9.80195H3.99973H5.33306V11.802V14.4686C5.33306 14.8373 5.63106 15.1353 5.99973 15.1353H9.99973C10.3684 15.1353 10.6664 14.8373 10.6664 14.4686V11.1353V9.80195H11.3331H13.3331C13.5897 9.80195 13.8224 9.65462 13.9337 9.42462C14.0444 9.19329 14.0137 8.91929 13.8537 8.71862L8.5204 2.05195C8.2664 1.73529 7.73306 1.73529 7.47906 2.05195L2.14573 8.71862C1.98573 8.91929 1.95506 9.19329 2.06573 9.42462C2.17706 9.65462 2.40973 9.80195 2.6664 9.80195Z"
        className={fillColor}
      />
    </svg>
  );
};

UpvoteIconFill.propTypes = {
  fillColor: PropTypes.string,
};

const DotIcon = ({ fillColor }) => {
  return (
    <svg
      width="3"
      height="3"
      viewBox="0 0 3 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.62 2.13275C1.068 2.13275 0.612 1.67675 0.612 1.12475C0.612 0.57275 1.068 0.11675 1.62 0.11675C2.172 0.11675 2.628 0.57275 2.628 1.12475C2.628 1.67675 2.172 2.13275 1.62 2.13275Z"
        className={fillColor}
      />
    </svg>
  );
};

DotIcon.propTypes = {
  fillColor: PropTypes.string,
};

const IconPrevSGV = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8467_70825)">
        <g filter="url(#filter0_b_8467_70825)">
          <circle
            cx="16"
            cy="16"
            r="16"
            transform="matrix(-1 0 0 1 32 0)"
            fill="url(#paint0_linear_8467_70825)"
            fillOpacity="0.4"
          />
          <circle
            cx="16"
            cy="16"
            r="15.6875"
            transform="matrix(-1 0 0 1 32 0)"
            stroke="url(#paint1_linear_8467_70825)"
            strokeOpacity="0.4"
            strokeWidth="0.625014"
          />
        </g>
        <path
          d="M17 12L13 16L17 20"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_8467_70825"
          x="-8"
          y="-8"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_8467_70825"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_8467_70825"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8467_70825"
          x1="29.2148"
          y1="0.400048"
          x2="1.61562"
          y2="29.8138"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8467_70825"
          x1="0.400396"
          y1="0.400048"
          x2="31.6004"
          y2="31.6156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_8467_70825">
          <rect
            width="32"
            height="32"
            rx="16"
            transform="matrix(-1 0 0 1 32 0)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconNextSGV = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8467_70759)">
        <g filter="url(#filter0_b_8467_70759)">
          <circle
            cx="16"
            cy="16"
            r="16"
            fill="url(#paint0_linear_8467_70759)"
            fillOpacity="0.4"
          />
          <circle
            cx="16"
            cy="16"
            r="15.6875"
            stroke="url(#paint1_linear_8467_70759)"
            strokeWidth="0.625014"
          />
        </g>
        <path
          d="M15 12L19 16L15 20"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_8467_70759"
          x="-8"
          y="-8"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_8467_70759"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_8467_70759"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8467_70759"
          x1="29.2148"
          y1="0.400048"
          x2="1.61562"
          y2="29.8138"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8467_70759"
          x1="0.400396"
          y1="0.400048"
          x2="31.6004"
          y2="31.6156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_8467_70759">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TopVoted = ({ is_winner, formatText }) => {
  const isMobile = useIsMobile();

  // Top Voted pada Galeri Foto dan Video tetap regular pada mobile device.
  return (
    <>
      {is_winner ? (
        <div
          className={clsx(
            isMobile && formatText
              ? "h-[27px] w-[107.5px]"
              : "h-[31px] w-[124px]"
          )}
        >
          <Image
            objectFit="contain"
            alt="Label Top Voted"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/winner/top-voted-${isMobile && formatText ? "small" : "regular"}.png`}
          />
        </div>
      ) : null}
    </>
  );
};
TopVoted.propTypes = {
  is_winner: PropTypes.bool,
  formatText: PropTypes.bool,
};
TopVoted.defaultValue = {
  formatText: false,
};
