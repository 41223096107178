import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import { Description } from "../shared/description/description"
import Link from "@/components/shared/link/link"
import ButtonBack from "../shared/button-back/button-back"
import PropTypes from "prop-types"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useContext, useEffect, useRef } from "react"
import { useInView } from "react-intersection-observer"
import { useWindowSize } from "@/hooks/use-window-size"
import { PrevPath } from "@/hooks/use-prev-path-ctx"
import { SuccessIcon } from "../sections/article-details"
import ButtonAction from "../shared/button-action/button-action"
import CheckBox from "../edit-profile/checkbox"
import DOMPurify from "isomorphic-dompurify"
import { useForm } from "react-hook-form"
import { ClientApiList } from "@/lib/api/client-service"
import UseQueryParams from "@/hooks/use-query-params"
import { postMessage } from "@/helper/utils";

export const LandingQuiz = (props) => {
  const QUERYNAME = "isGameStart"
  const { handlePushQuery, handleFilterQuery } = UseQueryParams()
  const linkRef = useRef(null)
  const { setStage, game, quiz, finished, detail } = props
  const init = useRef(false)
  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const datalayer = useDatalayer()
  const { prevPath } = useContext(PrevPath)

  useEffect(() => {
    if (
      linkRef.current &&
      detail?.data?.data?.tnc &&
      detail?.data?.data?.tnc.includes("target=_blank") &&
      linkRef.current.querySelector("a") &&
      !String(linkRef.current?.querySelector("a")).includes("target=_blank")
    )
      linkRef.current?.querySelector("a")?.setAttribute("target", "_blank")
    linkRef.current?.querySelector("a")?.setAttribute("rel", "noopener")
  })

  const { register, watch, setValue } = useForm({
    isAgree: false,
  })

  useEffect(() => {
    if (detail?.data.data.is_tnc_checked && !init.current) {
      setValue("isAgree", detail.data.data.is_tnc_checked)
    }
  }, [detail])

  const handleBtnAria = () => {
    return detail?.data.data.is_tnc
      ? detail?.data.data.is_tnc && !watch("isAgree")
      : false
  }

  const handleBtnIntent = () => {
    if (!detail?.data.data.is_tnc) return "primary"
    else return watch("isAgree") ? "primary" : "primary_disable"
  }

  useEffect(() => {
    handleFilterQuery(QUERYNAME)
  }, [])

  const StartGameCta = (
    <ButtonAction
      intent={handleBtnIntent()}
      data-testid="start-game-cta"
      className="w-full"
      disabled={handleBtnAria()}
      onClick={() => {
        handlePushQuery(false, [QUERYNAME, true])
        handleTNC()
        setStage(2)
        datalayer.push({
          event: "general_event",
          event_name: "click_mulai_sekarang_quiz",
          feature: "simple engagement",
          engagement_type: "games",
          engagement_name: "quiz",
          campaign_name: quiz?.is_special_engagement
            ? "why not 2025"
            : quiz.campaign_name.toLowerCase(), // e.g all
          event_label: `quiz - ${quiz.subtitle_introduction.toLowerCase()}`,
          level: 1,
          content_id: quiz.engagement_uuid,
        })
      }}
    >
      Mulai Sekarang
    </ButtonAction>
  )

  const [landingRef, inView] = useInView({
    triggerOnce: true,
  })

  const datalayerView = async () => {
    datalayer.push({
      event: "general_event",
      event_name: "page_reached_on_simple_engagement",
      feature: "simple engagement",
      page_type: "simple engagement",
      campaign_name: quiz?.is_special_engagement
        ? "why not 2025"
        : quiz.campaign_name.toLowerCase(), // e.g all
      // userld: dataProfile?.data.data.dataProfile,
      event_label: `quiz - ${quiz.subtitle_introduction.toLowerCase()}`,
      content_id: quiz.engagement_uuid,
    })
  }

  useEffect(() => {
    if (inView) {
      datalayerView()
    }
    // eslint-disable-next-line
  }, [inView])

  const wPointDesk =
    process.env.NEXT_PUBLIC_NAME === "amild" ? " w-[32px]" : " w-[24px]"
  const wPointMob =
    process.env.NEXT_PUBLIC_NAME === "amild" ? " w-[28px]" : " w-[16px]"

  const pointGet = quiz.point && (
    <div
      className={clsx(
        "flex items-center text-text-1 font-bold font-font-family-7 gap-[5px]",
        isDesktop ? "text-base" : "text-sm mt-[10px]"
      )}
    >
      {finished === 1 ? (
        <SuccessIcon />
      ) : (
        <div
          className={clsx(
            "relative aspect-square",
            isDesktop ? wPointDesk : wPointMob
          )}
        >
          <Image
            objectFit="cover"
            alt="icon"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : "reward-profile.svg"}`}
          />
        </div>
      )}
      <span>{quiz.point} Poin</span>
    </div>
  )

  const handleTNC = async () => {
    try {
      const { data } = await ClientApiList.setTncEngagement({
        engagement_uuid: quiz.engagement_uuid,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const blackGradient =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe" ||
    process.env.NEXT_PUBLIC_NAME === "magnum"

  useEffect(() => {
    postMessage("hello from article detail")
  })

  return (
    <div className={clsx("relative w-full px-[10px]", isDesktop && "flex")}>
      <div ref={landingRef} />
      <Link href={prevPath || "/"} passHref>
        <ButtonBack className="absolute top-[-10px] left-[6px]" />
      </Link>
      {!isDesktop && (
        <h2
          data-testid="mobile-title"
          className="text-center text-text-2 text-xl font-medium font-font-family-6"
        >
          Quiz Challenge
        </h2>
      )}
      <div
        className={clsx(
          "relative w-full overflow-hidden rounded-[10px] ",
          isDesktop
            ? "mt-0 max-w-[838px] min-w-[638px] aspect-[638/604] rounded-r-none"
            : "mt-[30px] aspect-[355/267]"
        )}
      >
        {console.log(quiz, "quiz")}
        <Image
          alt="quiz-cover"
          objectFit="cover"
          data-testid="intro-img"
          src={
            isDesktop
              ? quiz?.desktop_image_introduction_url
              : quiz?.mobile_image_introduction_url
          }
        />
      </div>
      <div
        className={clsx(
          "bg-bg-3 rounded-[10px] border-[1px] border-cta-4 p-[15px] w-full",
          isDesktop
            ? "flex flex-col justify-center rounded-l-none py-[63px] px-[55px]"
            : "my-[10px]"
        )}
      >
        {isDesktop && (
          <div className="flex gap-[20px]">
            <p className="w-fit bg-cta-3 rounded-[10px] py-[10px] px-[15px] font-medium font-font-family-6 text-center text-text-1 text-sm tracking-[0.14px]">
              Quiz Challenge
            </p>
            {pointGet}
          </div>
        )}
        <p
          data-testid="sub-intro"
          className={clsx(
            "font-bold text-text-2 capitalize font-font-family-7",
            isDesktop ? "text-[40px] mt-[20px]" : "text-base"
          )}
        >
          {quiz.subtitle_introduction}
        </p>
        {!isDesktop && pointGet}
        <Description
          className={clsx(
            "text-text-2 ",
            isDesktop
              ? "text-xl leading-[32px] mt-[20px] mb-[20px]"
              : "text-sm leading-[24px] mt-[10px] mb-[10px]"
          )}
          description={quiz.desc_introduction}
        />
        {detail?.data.data.is_tnc ? (
          <div>
            <form method="POST" className="flex gap-[5px]">
              <CheckBox
                register={register}
                name={"isAgree"}
                disabled={detail.data.data.is_tnc_checked}
                isCenter={true}
                className="top-[calc(50%_-_10px)]"
              />
              <div
                ref={linkRef}
                className="text-[12px] lg:text-[14px] font-medium underline leading-[18px] font-medium text-text-1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(detail.data.data.tnc),
                }}
              ></div>
            </form>
          </div>
        ) : null}
        {isDesktop && game && (
          <div className="w-[343px] mt-[30px]">{StartGameCta}</div>
        )}
      </div>
      {!isDesktop && game && (
        <div
          className={clsx(
            "fixed z-[20] bottom-[56px] left-2/4 -translate-x-1/2 w-full px-[10px] pt-[80px] pb-[20px] ",
            blackGradient
              ? "bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0,_#000_40%)]"
              : "bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0,_#FFF_40%)]"
          )}
        >
          {StartGameCta}
        </div>
      )}
    </div>
  )
}

LandingQuiz.propTypes = {
  setStage: PropTypes.func,
  game: PropTypes.array,
  quiz: PropTypes.object,
  detail: PropTypes.object,
  finished: PropTypes.number,
}
