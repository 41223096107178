"use client"
import React, { useEffect, useState } from "react"
import { ClientApiList } from "@/lib/api/client-service"
import crypto from "crypto"
import { ToastComponent } from "../toast"
import { useDatalayer } from "@/hooks/use-datalayer"
import UseQueryParams from "@/hooks/use-query-params"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { Spinner } from "../shared/spinner"
import { useRouter } from "next/navigation"

export const encryptGame = (data) => {
  let dechiper = crypto.createCipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "utf8", "hex")
  dec += dechiper.final("hex")
  return dec
}

export const decryptData = (data) => {
  let dechiper = crypto.createDecipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "hex", "utf8")
  dec += dechiper.final("utf8")
  return JSON.parse(dec)
}

export const IframeWhyNot = ({ page_uuid }) => {
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const datalayer = useDatalayer()
  const [point, setPoint] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const [url, setUrl] = useState(true)
  const { isLogin, isLoading } = useIsLogin()
  const [isLoadingAuth, setIsLoadingAuth] = useState(true)
  const { replace } = useRouter()

  function handlePoint(point) {
    if (point !== 0) {
      setPoint(point)
      handlePushQuery(false, [QUERYPARAM, true])
      setIsSuccess(true)
    }
  }

  async function handleSubmit(mission) {
    const { data: dataReturn } = await ClientApiList.postTrack({
      source: "AG",
      type: "whyNot",
      detail: mission,
    })
    handlePoint(dataReturn.data.data.point)
  }

  function handleDataLayer(data) {
    datalayer.push(data)
  }

  async function handleEvent(url) {
    const engagement = await handleGetMissions()

    let newuUrl = url + `?data=${engagement}`
    setUrl(newuUrl)
    setIsLoadingAuth(false)
  }

  async function eventHandler(event) {
    const data = event.data
    if (data.action == "datalayer") handleDataLayer(data.value[0])
    else if (data.action == "backhome")
      replace(`${process.env.NEXT_PUBLIC_META_URL}/br`)
    else if (data.action === "tracking") {
      if (data?.data?.action === "completed") handleSubmit(data?.data?.mission)
    }
  }

  const events = (e) => {
    if (e.origin !== process.env.NEXT_PUBLIC_AG_DOMAIN) return
    eventHandler(e)
  }

  const handleGetMissions = async () => {
    try {
      const { data } = await ClientApiList.getMissionList({
        page_uuid,
      })

      return data.data.data.special_engagement_encrypted
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    let url = `${process.env.NEXT_PUBLIC_AG_DOMAIN}/why-not-hero-engagement/index.html`

    isLogin && !isLoading && handleEvent(url)

    window.addEventListener("message", events, false)

    return () => {
      window.removeEventListener("message", events, false)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, isLogin])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo berhasil dapetin ${point} poin!`

  if (isLoadingAuth)
    return (
      <div className="h-[calc(100vh-170px)] flex items-center">
        <Spinner />
      </div>
    )

  return (
    <div className="w-[100%] h-[100%] h-[calc(100dvh-56px)] min-w-[100vw]">
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc="Lanjut eksplor dan kumpulkan lebih banyak poin!"
        isOpen={isSuccess}
      />
      <iframe
        className="block w-[100dvw] h-[calc(100dvh-56px)]"
        seamless="seamless"
        title="game-view"
        // src="https://g.minigim.fun/amild-bin-it-right/index.html"
        src={url}
        frameBorder="0"
        sandbox="allow-scripts allow-same-origin"
      ></iframe>
    </div>
  )
}
