"use client";
import React, { useEffect, useState } from "react";
import "swiper/css";
import EditNav from "../edit-profile/edit-nav";
import EditHeader from "../edit-profile/edit-header";

import { editProfile } from "../edit-profile/data/edit-profile";
import { ClientApiList } from "@/lib/api/client-service";
import EditForm from "../edit-profile/edit-form";
import { ToastComponent } from "../toast";
import BackBanner from "../shared/back-banner";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { Image } from "../shared/image/image";
import { capitalizeFirstLetter, urlAsset } from "@/helper/utils";
import FadeComponent from "../shared/fade-component/fade-component";

export function EditProfile() {
  const [cities, setCities] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('Berhasil Ubah Profile');
  const isMobile = useIsMobile();
  // const [profileData, setProfileData] = useState()

  // const handleGetData = async () => {
  //   try {
  //     const { data } = await ClientApiList.getProfileData()
  //     const dob = new Date(data.data.data.dob)
  //     const date = dob.getDate()
  //     const month = dob.getMonth() + 1
  //     const year = dob.getFullYear()
  //     const brandId = data.data.data.favorite_brand.brand_id
  //     setProfileData({ ...data.data.data, date, month, year, brandId })
  //   } catch (err) {
  //     console.log("[Error]", err)
  //   }
  // }

  const handleGetCities = async () => {
    try {
      const { data } = await ClientApiList.getMasterCity();
      setCities([...data.data.list_city]);
    } catch (err) {
      console.log("[Error]", err);
    }
  };

  const handleGetBrands = async () => {
    try {
      const { data } = await ClientApiList.getMasterBrands();
      setBrands(
        data.data.map((brand) => ({
          id: brand.brand_preference_id,
          name: brand.brand_preference_name,
        }))
      );
    } catch (err) {
      console.log("[Error]", err);
    }
  };

  useEffect(() => {
    // handleGetData()
    handleGetCities();
    handleGetBrands();
  }, []);

  return (
    <>
      {/* <BackBanner
        className="h-[607px] top-[58px] lg:top-[72px]"
        bgImg={`/assets/amild/profile-edit/${isMobile ? "bg-mobile.jpg" : "bg-desktop.jpg"}`}
      /> */}
      <EditNav />
      {process.env.NEXT_PUBLIC_NAME === "marlboro" ? null :<Image
        className="absolute h-[100%] w-[100%]"
        alt="delete"
        src={`${urlAsset()}/profile-edit/${isMobile ? "bg-mobile.png" : "bg-desktop.png"}`}
      />}
      <ToastComponent
        onClick={() => {
          setToastMessage('Berhasil Ubah Profile')
          setIsSuccess(false);
        }}
        title={toastMessage}
        desc={`Lanjut eksplor ${capitalizeFirstLetter(String(process.env.NEXT_PUBLIC_NAME))}.id sekarang!`}
        isOpen={isSuccess}
      />
      <FadeComponent>
      <div className="py-[20px] lg:py-[32px] px-[10px] relative lg:p-0">
        <div className="relative z-[3]  max-w-[462px] mx-auto">
          <EditHeader
            title={editProfile.title}
            description={editProfile.description}
          />
          {/* {profileData && cities ? ( */}
          <EditForm
            brands={brands}
            setToastMessage={setToastMessage}
            // profileData={profileData}
            cities={cities}
            setIsSuccess={setIsSuccess}
          />
          {/* ) : (
            <p className="text-[16px] pl-[5px] font-medium font-font-family-6 text-text-2 leading-[22px] mb-[20px]">
              Loading...
            </p>
          )} */}
        </div>
      </div>
      </FadeComponent>
    </>
  );
}
