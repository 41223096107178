import { cn } from "@/lib/utils"
import React from "react"

const ReservationTopCardInfo = ({ icon, label, className, iconClass }) => {
  return (
    <div
      className={cn("mb-[5px] px-[5px] text-text-2 flex items-center gap-[5px]", className)}
    >
      <div
        className={cn("w-[14px] h-[14px] lg:w-[20px] lg:h-[20px]", iconClass)}
      >
        {icon}
      </div>
      <div className="text-wrap break-all">{label}</div>
    </div>
  )
}

export default ReservationTopCardInfo
