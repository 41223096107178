import React from "react"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"
import { useRouter } from "next/navigation"
import ButtonAction from "@/components/shared/button-action/button-action"

const Rsvp = ({ uuid, className }) => {
  const router = useRouter()
  const handleToReservation = (e) => {
    e.stopPropagation()
    console.log("uuid", uuid)
    router.push(`/event/reservation/br?uuid=${uuid}`)
  }

  return (
    <ButtonAction
      intent={"primary"}
      className="absolute w-fit z-[50] min-w-[73px] max-h-[30px] mt-[10px] md:mt-0 !p-[5px] !px-[10px] !lg:px-[5px]"
      onClick={(e) => handleToReservation(e)}
    >
      {/* <div
        className={twMerge(
          "min-w-[73px] min-h-[30px] text-cta-2 md:flex w-fit ml-auto md:ml-0 md:mr-auto mt-[10px] md:mt-0 py-[5px] px-[10px] lg:px-[5px] rounded-[10px] flex gap-[5px] ml-auto items-center gap-[5px] lg:justify-center",
          className
        )}
      > */}
      <div className="flex items-center gap-[5px]">
        <div className="w-[16px] md:w-[20px] h-[16px] md:h-[20px] text-cta-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14.6468 12.0007V12.6673C14.6468 13.7673 14.6468 14.6673 12.6468 14.6673H3.31348C1.31348 14.6673 1.31348 13.7673 1.31348 12.6673V12.0007C1.31348 11.634 1.61348 11.334 1.98014 11.334H13.9801C14.3468 11.334 14.6468 11.634 14.6468 12.0007Z"
              fill="white"
            />
            <path
              d="M9.60635 3.45318C9.63969 3.31985 9.65969 3.19318 9.66635 3.05318C9.68635 2.27985 9.21302 1.59985 8.46635 1.39985C7.34635 1.09318 6.33302 1.93318 6.33302 2.99985C6.33302 3.15985 6.35302 3.30651 6.39302 3.45318C3.98635 3.96651 2.17969 6.10651 2.17969 8.66651V9.66652C2.17969 10.0332 2.47969 10.3332 2.84635 10.3332H13.1464C13.513 10.3332 13.813 10.0332 13.813 9.66652V8.66651C13.813 6.10651 12.013 3.97318 9.60635 3.45318ZM9.99969 7.83318H5.99969C5.72635 7.83318 5.49969 7.60651 5.49969 7.33318C5.49969 7.05985 5.72635 6.83318 5.99969 6.83318H9.99969C10.273 6.83318 10.4997 7.05985 10.4997 7.33318C10.4997 7.60651 10.273 7.83318 9.99969 7.83318Z"
              fill="white"
            />
          </svg>
        </div>
        <p className="text-[12px] tracking-[0.12px] leading-[16px] md:leading-[20px]">
          RSVP
        </p>
      </div>
      {/* </di v> */}
    </ButtonAction>
  )
}

Rsvp.propTypes = {
  className: PropTypes.string,
}

export default Rsvp
