"use client"

import { cva } from "class-variance-authority"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import { Swiper, SwiperSlide } from "swiper/react"
import { useEffect, useRef, useState } from "react"
import Separator from "../slide-menu/separator/separator"
import { Description } from "../shared/description/description"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import PropTypes from "prop-types"
import { ClientApiList } from "@/lib/api/client-service"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { cn } from "@/lib/utils";

export function AboutBenefits(props) {
  const {
    icon_url,
    title,
    subtitle,
    description,
    bg_img_url_mobile,
    bg_img_url_desktop,
    data = {},
  } = props
  const swiperRef = useRef()

  const isMobile = useIsMobile()
  const { isLogin, isLoading } = useIsLogin()
  const [dataUser, setDataUser] = useState({})
  const [pointUb, setPointUb] = useState("")
  const [dataTier, setDataTier] = useState(data)

  const bgImg = process.env.NEXT_PUBLIC_NAME === "marlboro" ? null : isMobile ? bg_img_url_mobile : bg_img_url_desktop

  const getBenefit = async () => {
    const { data } = await ClientApiList.getAboutBenefit({
      is_branded: isLogin ? 1 : 0,
    })
    if (data) {
      setDataTier(data?.data.data)
    }
  }

  const getProfile = async () => {
    const { data } = await ClientApiList.getProfileData()
    if (data) {
      setDataUser(data?.data.data)
    }
  }

  const updatePointUb = async () => {
    const { data } = await ClientApiList.getPointUb()
    setPointUb(data?.data?.point)
  }

  useEffect(() => {
    if (!isLoading) getBenefit()

    if (isLogin && !isLoading) getProfile()
    else if (!isLogin && !isLoading) updatePointUb()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLogin, isLoading])

  return (
    <>
      {((isLogin && !isLoading && dataUser?.tier_id) ||
        (!isLogin && !isLoading && pointUb >= 0)) && (
        <div
          className={clsx(
            "w-full h-full px-[10px]",
            isMobile ? "mt-[45px]" : "mt-[60px]"
          )}
        >
          <div
            id="benefit"
            className="w-full h-full relative bg-bg-2 rounded-[15px] overflow-hidden text-text-4 pt-[20px] pb-[40px]"
          >
            <div className={clsx("absolute top-0 bottom-0 left-0 right-0 z-1")}>
              {bgImg && <Image alt="bg" src={bgImg} />}
            </div>
            <div className="relative z-1">
              <div
                className={clsx(
                  "flex items-center gap-[10px]",
                  isMobile ? "px-[15px]" : "px-[20px]"
                )}
              >
                <div
                  className={clsx(
                    "relative aspect-square",
                    isMobile ? "w-[72px]" : "w-[88px]"
                  )}
                >
                  <Image alt="about-icon" src={icon_url} />
                </div>
                <h2
                  className={clsx(
                    "font-bold tracking-[-1px] uppercase font-font-family-3",
                    isMobile
                      ? "text-[30px] leading-[32px] max-w-[140px]"
                      : " text-[40px] leading-[50px] w-[230px]"
                  )}
                >
                  {title}
                </h2>
              </div>
              <div
                className={clsx(
                  "mt-[15px]",
                  isMobile ? "px-[15px]" : "px-[20px]"
                )}
              >
                <Description
                  className={clsx(
                    "font-bold font-font-family-7 capitalize",
                    isMobile ? "text-base" : "text-[20px] leading-[27px]"
                  )}
                  description={subtitle}
                />
                <Description
                  className={clsx(
                    "mt-[5px]",
                    isMobile ? "text-sm w-[325px]" : "text-base w-[750px]"
                  )}
                  description={description}
                />
              </div>
              <Swiper
                spaceBetween={15}
                slidesPerView="auto"
                // onSlideChange={(swiper) => {
                //   setActiveIndex(swiper.activeIndex)
                // }}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper
                  // setActiveIndex(swiper.activeIndex)
                }}
                style={{
                  padding: "0 20px",
                }}
              >
                <SwiperSlide
                  style={{
                    width: "307px",
                  }}
                >
                  <ProgressBar
                    position="start"
                    statusRight={dataUser?.tier_id > 1 ? "pass" : "active"}
                    statusLeft="pass"
                    tier={dataTier?.tier_progres[0]?.tier_name}
                    img={dataTier?.tier_progres[0]?.icon_url}
                  />
                  <HowToItem
                    dataUser={dataUser}
                    data={dataTier?.tier_progres[0]}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    width: "307px",
                  }}
                >
                  <ProgressBar
                    position="middle"
                    statusRight={dataUser?.tier_id > 2 ? "pass" : "active"}
                    statusLeft={dataUser?.tier_id > 1 ? "pass" : "active"}
                    tier={dataTier?.tier_progres[1]?.tier_name}
                    img={dataTier?.tier_progres[1]?.icon_url}
                  />
                  <HowToItem
                    dataUser={dataUser}
                    data={dataTier?.tier_progres[1]}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    width: "307px",
                  }}
                >
                  <ProgressBar
                    position="middle"
                    statusRight={dataUser?.tier_id > 3 ? "pass" : "active"}
                    statusLeft={dataUser?.tier_id > 2 ? "pass" : "active"}
                    tier={dataTier?.tier_progres[2]?.tier_name}
                    img={dataTier?.tier_progres[2]?.icon_url}
                  />
                  <HowToItem
                    dataUser={dataUser}
                    data={dataTier?.tier_progres[2]}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    width: "307px",
                  }}
                >
                  <ProgressBar
                    position="end"
                    statusLeft={dataUser?.tier_id > 3 ? "pass" : "active"}
                    tier={dataTier?.tier_progres[3]?.tier_name}
                    img={dataTier?.tier_progres[3]?.icon_url}
                  />
                  <HowToItem
                    dataUser={dataUser}
                    data={dataTier?.tier_progres[3]}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

AboutBenefits.propTypes = {
  icon_url: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object,
  bg_img_url_mobile: PropTypes.string,
  bg_img_url_desktop: PropTypes.string,
}

/**
 * @type {import('react').FC<{
 * 	position : 'start' | 'end' | 'middle',
 * 	status: 'pass' | 'active'
 * }>}
 */

const barLineRight = cva("absolute h-[6px] left-[50%] ", {
  variants: {
    position: {
      start: ["block", "right-[-8px]"],
      end: ["hidden"],
      middle: ["block", "right-[-8px]"],
    },
    statusRight: {
      pass: ["bg-cta-1"],
      active: ["bg-cta-4"],
    },
  },
  compoundVariants: [{ position: "start", statusRight: "pass" }],
  defaultVariants: {
    position: "left",
    statusRight: "pass",
  },
})

const barLineLeft = cva("absolute h-[6px] right-[50%]", {
  variants: {
    position: {
      start: ["block", "left-0", "rounded-[10px]"],
      end: ["block", "left-[-8px]"],
      middle: ["block", "left-[-8px]"],
    },
    statusLeft: {
      pass: ["bg-cta-1"],
      active: ["bg-cta-4"],
    },
  },
  compoundVariants: [{ position: "start", statusLeft: "pass" }],
  defaultVariants: {
    position: "left",
    statusLeft: "pass",
  },
})

const ProgressBar = ({ position, statusRight, statusLeft, tier, img }) => {
  const isMobile = useIsMobile()
  const switchBGLeft = () => {
    switch (statusLeft) {
      case "pass":
        return "var(--cta_1)"
      case "active":
        return "var(--cta_4)"
      default:
        return "transparent"
    }
  }
  const switchBGRight = () => {
    switch (statusRight) {
      case "pass":
        return "var(--cta_1)"
      case "active":
        return "var(--cta_4)"
      default:
        return "transparent"
    }
  }
  return (
    <div
      className={clsx(
        "relative w-full h-[63px] mb-[25px] flex justify-center items-center",
        !isMobile && "mt-[10px]"
      )}
    >
      <div
        className={barLineLeft({ position, statusLeft })}
        style={{ background: switchBGLeft() }}
      />
      <div className="absolute top-[15px] left-2/4 -translate-x-1/2 z-[2] flex flex-col justify-center items-center">
        <div className="relative aspect-square w-[40px]">
          <Image alt="icon" src={img} />
        </div>
        <span
          className={clsx(
            "font-bold font-font-family-7 capitalize",
            isMobile ? "text-base" : "text-xl"
          )}
        >
          {tier}
        </span>
      </div>
      <div
        className={barLineRight({ position, statusRight })}
        style={{ background: switchBGRight() }}
      />
    </div>
  )
}

ProgressBar.propTypes = {
  position: PropTypes.string,
  statusRight: PropTypes.string,
  statusLeft: PropTypes.string,
  tier: PropTypes.string,
  img: PropTypes.string,
}

const HowToItem = ({ data, dataUser }) => {
  const { isLogin } = useIsLogin()
  const isMobile = useIsMobile()

  const { execute: register } = useReqUrl({
    page: "register",
  })

  return (
    <div className="relative w-full pt-[10px] rounded-[10px] overflow-hidden bg-bg-3 text-text-2">
      <span className="px-[10px] text-sm font-bold font-font-family-3 ">
        How to
      </span>
      <Separator className={cn("bg-[#C7C7C7] h-[1px] my-[10px]", 'bg-cta-4')} />
      <div className="flex flex-col gap-[10px] mb-[10px]">
        {data?.how_to?.map((item, index) => (
          <div
            key={index}
            className="w-full px-[15px] flex items-center gap-[5px]"
          >
            <div className="w-[24px] aspect-square">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="8"
                  className={clsx(
                    data?.tier_id <= dataUser?.tier_id ||
                      (data?.tier_id == 2 &&
                        index == 0 &&
                        dataUser?.email_verified) ||
                      (data?.tier_id == 2 &&
                        index == 1 &&
                        dataUser?.profile_completed) ||
                      (data?.tier_id == 2 &&
                        index == 2 &&
                        dataUser?.phone_verified) ||
                      data?.how_to_status[index]
                      ? "fill-icon-1"
                      : "fill-text-3"
                  )}
                />
                <path
                  d="M8.5 12L11 15L16 9"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <Description
              className={clsx(
                "text-[14px] leading-[20px] font-medium font-font-family-6",
                data?.tier_id >= 3 && !isLogin && "max-w-[185px]"
              )}
              description={item}
            />
            {data?.tier_id >= 3 && !isLogin && (
              <button
                onClick={() => register()}
                className="flex justify-center items-center gap-[4px] ml-auto mr-0"
              >
                <span className="text-[14px] leading-[22px] font-bold font-font-family-7 text-text-1">
                  Daftar
                </span>
                <ChevronRigth color="stroke-text-1" />
              </button>
            )}
            {data?.how_to_url?.length > 0 &&
              ((data?.tier_id == 2 &&
                index == 0 &&
                !dataUser?.email_verified) ||
                (data?.tier_id == 2 &&
                  index == 1 &&
                  !dataUser?.profile_completed) ||
                (data?.tier_id == 2 &&
                  index == 2 &&
                  !dataUser?.phone_verified)) && (
                <Link
                  href={data?.how_to_url[index]}
                  passHref
                  className="flex justify-center items-center gap-[4px] ml-auto mr-0"
                >
                  <Description
                    className="text-[14px] leading-[22px] font-bold font-font-family-7 text-text-1 capitalize"
                    description={item.split(" ")[0]}
                  />
                  <ChevronRigth color="stroke-text-1" />
                </Link>
              )}
            {(data?.tier_id <= dataUser?.tier_id ||
              (data?.tier_id == 2 && index == 0 && dataUser?.email_verified) ||
              (data?.tier_id == 2 &&
                index == 1 &&
                dataUser?.profile_completed) ||
              (data?.tier_id == 2 && index == 2 && dataUser?.phone_verified) ||
              data?.how_to_status[index]) && (
              <span className="text-[14px] leading-[22px] text-[#C7C7C7] font-bold font-font-family-7 ml-auto mr-0">
                Selesai
              </span>
            )}
          </div>
        ))}
      </div>
      {data?.benefit != null && (
        <div className="relative rounded-[10px] overflow-hidden bg-cta-1">
          {data?.bg_img_url && (
            <div className="absolute rounded-b-[10px] top-[0] left-0 right-0 h-full w-full z-1 overflow-hidden">
              <Image alt="bg-benefit" src={data?.bg_img_url} />
            </div>
          )}
          <div
            className={
              data?.benefit?.length > 1
                ? "relative w-full min-h-[182px] z-[2] px-[14px] py-[15px]"
                : "relative w-full min-h-[106px] z-[2] px-[14px] py-[15px]"
            }
          >
            <div className="relative w-fit min-w-[83px] px-[5px] mb-[10px] flex items-center gap-[5px] bg-bg-3 rounded-[10px]">
              {data?.icon_url && (
                <div className={clsx("relative w-[16px] aspect-square")}>
                  <Image alt="benefit-icon" src={data?.icon_url} />
                </div>
              )}
              <span className="text-[14px] leading-[20px] font-bold font-font-family-7">
                Benefit
              </span>
            </div>
            {data?.benefit?.map((item, index) => (
              <Description
                key={index}
                className={clsx(
                  "leading-normal text-text-3 font-medium font-font-family-6 tracking-[0.25px] mb-[10px]",
                  isMobile ? "text-xs" : "text-sm"
                )}
                description={item}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

HowToItem.propTypes = {
  data: PropTypes.object,
  dataUser: PropTypes.object,
}

const ChevronRigth = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill="none"
    >
      <path
        className={color}
        d="M4.5 8L8.03553 11.5355L4.5 15.0711"
        // stroke="#CD161C"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

ChevronRigth.propTypes = {
  color: PropTypes.string,
}
