"use client"
import useReservation from "@/hooks/use-reservation"
import useRenderForm from "@/hooks/useRenderForm"
import { ClientApiList } from "@/lib/api/client-service"
import DOMPurify from "isomorphic-dompurify"
import React, { Fragment, useRef, useState } from "react"
import ButtonAction from "../shared/button-action/button-action"
import CheckBox from "../edit-profile/checkbox"
import ReservationFormBox from "./reservation-formbox"
import ReCAPTCHA from "react-google-recaptcha"
import PopupLink from "./reservation-otp-popup"
import { useSearchParams } from "next/navigation"
import ReservationConfirmation from "./reservation-confirmation"

const ReservationForm = ({ reservation, data }) => {
  const [handleRenderLabel, handleRenderInput] = useRenderForm()
  const searchParams = useSearchParams()
  const [isPopupVerifyOpen, setIsPopupVerifyOpen] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const firstSections = [
    ...reservation.contactPerson.form_items.filter((item) => {
      return data.feature[item.key_label] || item.label === "Nama Partisipan"
    }),
  ]
  const secondSections = [
    ...reservation.AdditionalInformation.form_items.filter((item) => {
      return data.feature[item.key_label]
    }),
  ]
  const captchaRef = useRef(null)

  const {
    register,
    getValues,
    // resetForm,
    watch,
    onSubmit,
    // setError,
    errors,
    // setFocus,
  } = useReservation({
    isConfirmed,
    setIsConfirmed,
    setIsConfirmationOpen,
    setIsError,
    captchaRef,
    setIsLoading,
    setIsPopupVerifyOpen,
  })

  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank")
      node.setAttribute("rel", "noopener")
    }
  })

  const isInputDisabled = (name, propDisabled) => {
    if (name === "email") return getValues("email_verified")
    if (name === "phone") return getValues("phone_verified")
    else return propDisabled
  }

  const handleRequestOTP = async (resend = false) => {
    const response = await ClientApiList.sendEmail()
  }

  return (
    <>
      <ReservationConfirmation
        isOpen={isConfirmationOpen}
        handleSubmit={onSubmit}
        handleIsOpen={setIsConfirmationOpen}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      <PopupLink
        event_uuid={data.detail.event_uuid}
        isOpen={isPopupVerifyOpen}
        setIsOpen={setIsPopupVerifyOpen}
        handleRequestOTP={handleRequestOTP}
      />
      <div className="bg-bg-3 rounded-[10px] relative z-[1] max-w-[calc(100dvw-20px)] mx-auto mt-[12px] lg:mt-[280px] min-h-[100px] p-[15px] w-[100%] lg:max-w-[461px] mx-auto">
        <div className="text-[20px] leading-[28px] font-bold text-text-2 mb-[10px]">
          {data?.detail.rsvp_title}
        </div>
        <div className="mb-[15px] text-[16px] leading-[24px] font-[400] text-text-2">
          {data.detail?.rsvp_desciption}
        </div>
        <form method="POST" onSubmit={onSubmit}>
          {isError ? (
            <div className="bg-cta-3 p-[10px] mb-[10px] rounded-[10px] my-[5px] flex gap-[5px] items-center text-[12px] text-text-1 font-medium font-font-family-6 tracking-[0.25px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z"
                  fill="#FD2A29"
                />
              </svg>
              <p>{`Captcha harus diisi`}</p>
            </div>
          ) : null}
          <input
            {...register("event_uuid")}
            value={searchParams.get("uuid")}
            hidden
          />
          <ReservationFormBox title={reservation.contactPerson.section_title}>
            {firstSections.map((item) => (
              <Fragment key={item.label}>
                {handleRenderLabel(
                  item.label,
                  (item.name === "email" &&
                    getValues("email_verified") !== "") ||
                    (item.name === "phone" &&
                      !getValues("phone_verified") !== "")
                    ? item.link_label
                    : null,
                  (item.name === "email" &&
                    getValues("email_verified") !== "") ||
                    (item.name === "phone" &&
                      !getValues("phone_verified") !== "")
                    ? item.link + `?uuid=` + data.detail.event_uuid
                    : null,null, true
                )}
                <div className="relative">
                  {handleRenderInput(item.type, {
                    placeholder: item.placeholder,
                    disabled: isInputDisabled(item.name, item.disabled),
                    options: item.options,
                    register,
                    name: item.name,
                    getValues,
                    validation:
                      !getValues(item.name) && item.config ? item.config : {},
                    readOnly:
                      (item.name === "phone" && !getValues("phone")) ||
                      item.name === "email",
                  })}
                  {(item.name === "email" && getValues("email_verified")) ||
                  (item.name === "phone" && getValues("phone_verified")) ? (
                    <div className="absolute top-[12px] right-[10px] max-w-[16px] max-h-[16px]">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="vuesax/bold/verify">
                          <g id="verify">
                            <path
                              id="Vector"
                              d="M14.3726 7.16012L13.4659 6.10679C13.2926 5.90679 13.1526 5.53345 13.1526 5.26679V4.13345C13.1526 3.42679 12.5726 2.84679 11.8659 2.84679H10.7326C10.4726 2.84679 10.0926 2.70679 9.8926 2.53345L8.83927 1.62679C8.37927 1.23345 7.62594 1.23345 7.15927 1.62679L6.1126 2.54012C5.9126 2.70679 5.5326 2.84679 5.2726 2.84679H4.11927C3.4126 2.84679 2.8326 3.42679 2.8326 4.13345V5.27345C2.8326 5.53345 2.6926 5.90679 2.52594 6.10679L1.62594 7.16679C1.23927 7.62679 1.23927 8.37345 1.62594 8.83345L2.52594 9.89345C2.6926 10.0935 2.8326 10.4668 2.8326 10.7268V11.8668C2.8326 12.5735 3.4126 13.1535 4.11927 13.1535H5.2726C5.5326 13.1535 5.9126 13.2935 6.1126 13.4668L7.16594 14.3735C7.62594 14.7668 8.37927 14.7668 8.84594 14.3735L9.89927 13.4668C10.0993 13.2935 10.4726 13.1535 10.7393 13.1535H11.8726C12.5793 13.1535 13.1593 12.5735 13.1593 11.8668V10.7335C13.1593 10.4735 13.2993 10.0935 13.4726 9.89345L14.3793 8.84012C14.7659 8.38012 14.7659 7.62012 14.3726 7.16012ZM10.7726 6.74012L7.5526 9.96012C7.45927 10.0535 7.3326 10.1068 7.19927 10.1068C7.06594 10.1068 6.93927 10.0535 6.84594 9.96012L5.2326 8.34679C5.03927 8.15345 5.03927 7.83345 5.2326 7.64012C5.42594 7.44679 5.74594 7.44679 5.93927 7.64012L7.19927 8.90012L10.0659 6.03345C10.2593 5.84012 10.5793 5.84012 10.7726 6.03345C10.9659 6.22679 10.9659 6.54679 10.7726 6.74012Z"
                              fill="#32D74B"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  ) : null}
                </div>
                {errors[item.name] ? (
                  <div className=" flex gap-[5px] items-center text-[12px] text-text-1 font-medium font-font-family-6 tracking-[0.25px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z"
                        fill="#FD2A29"
                      />
                    </svg>
                    <p>{errors[item.name].message}</p>
                  </div>
                ) : null}
              </Fragment>
            ))}
          </ReservationFormBox>
          {secondSections.length >= 1 ? (
            <ReservationFormBox
              title={reservation.AdditionalInformation.section_title}
            >
              {secondSections.map((item) => (
                <Fragment key={item.label}>
                  {handleRenderLabel(item.label, null, null, null, true)}
                  <div className="relative">
                    {handleRenderInput(item.type, {
                      placeholder: item.placeholder,
                      disabled: isInputDisabled(item.name, item.disabled),
                      options:
                        item.name === "city_id"
                          ? data?.detail?.event_rsvp_cities?.map((city) => {
                              return {
                                label: city.city_name,
                                value: city.city_name,
                              }
                            })
                          : item.name === "venue_id"
                            ? data?.detail?.event_rsvp_venues?.map((city) => {
                                return {
                                  label: city.venue_name,
                                  value: city.venue_name,
                                }
                              })
                            : null,
                      register,
                      name: item.name,
                      getValues,
                      validation:
                        !getValues(item.name) && item.config ? item.config : {},
                    })}
                  </div>
                  {errors[item.name] ? (
                    <div className=" flex gap-[5px] items-center text-[12px] text-text-1 font-medium font-font-family-6 tracking-[0.25px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z"
                          fill="#FD2A29"
                        />
                      </svg>
                      <p>{errors[item.name].message}</p>
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </ReservationFormBox>
          ) : null}
          {data && data?.feature["is_checkbox_showing"] ? (
            <div className="flex items-center gap-[5px] mb-[15px]">
              <CheckBox
                register={register}
                name={"isAgree"}
                disabled={false}
                isCenter={true}
                className="top-[calc(50%_-_10px)]"
              />
              <div
                // ref={"#"}
                className="text-[12px] lg:text-[14px] font-medium underline leading-[18px] font-medium text-text-1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.detail.rsvp_tnc),
                }}
              ></div>
            </div>
          ) : null}
          <ButtonAction
            disabled={
              data?.feature["is_checkbox_showing"]
                ? !watch("isAgree")
                : isLoading
                  ? true
                  : false
            }
            className={"max-w-[355px] mx-auto"}
            intent={
              data?.feature["is_checkbox_showing"]
                ? !watch("isAgree")
                  ? "primary_disable"
                  : isLoading
                    ? "primary_disable"
                    : "primary"
                : "primary"
            }
          >
            {data.detail.cta}
          </ButtonAction>
          <ReCAPTCHA
            ref={captchaRef}
            size="invisible"
            sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA}
          />
        </form>
      </div>
    </>
  )
}

export default ReservationForm
