export const reservation = {
  title: "Reservation Form",
  description: `Konfirmasi data diri lo buat ikutan event "Soundstream" tanggal 30 September 2023 di JIEXPO Kemayoran, Jakarta.`,
  contactPerson: {
    section_title: "Contact Person",
    form_items: [
      {
        label: "Nama Partisipan",
        type: "text",
        placeholder: "Josh Thompson",
        disabled: true,
        key: "fullname",
        name: "fullname",
      },
      {
        label: "Email",
        type: "text",
        // link_label: "Update Email",
        // link: "/change-email",
        // link_label_verified: "Verifikasi Email",
        // link_verified: "#",
        placeholder: "joshthompson@mail.com",
        // disabled: true,
        key: "email",
        name: "email",
        key_label: "is_email_showing",
        config: { required: "Silahkan isi email" },
      },
      {
        label: "Nomor Handphone",
        type: "phone",
        link_label: "Update Nomor",
        link: "/ubah-nomor",
        // link_label_verified: "Verifikasi Nomor",
        // link_verified: "/verifikasi-handphone",
        placeholder: "81234567890",
        key: "phone",
        name: "phone",
        key_label: "is_phone_number_showing",
        config: { required: "Silahkan lakukan update nomor" },
      },
    ],
  },
  AdditionalInformation: {
    section_title: "Informasi Event",
    form_items: [
      {
        label: "Jumlah Peserta Hadir",
        type: "text",
        placeholder: "Masukkan angka 0-100",
        // disabled: true,
        key: "participant",
        name: "participant",
        config: {
          max: 100,
          min: 0,
          required: "Silahkan masukkan jumlah participant",
        },
        key_label: "is_participant_showing",
      },
      {
        label: "Pilih Kota",
        type: "select",
        placeholder: "Pilih Kota",
        key: "city_id",
        name: "city_id",
        config: { required: "Silahkan pilih kota" },
        key_label: "is_city_showing",
      },
      {
        label: "Pilih Venue",
        type: "select",
        placeholder: "Pilih Venue",
        key: "venue_id",
        name: "venue_id",
        config: { required: "Silahkan pilih venue" },
        key_label: "is_venue_showing",
      },
    ],
  },
  submit_label: "Simpan Perubahan",
  cance_label: "Batal",
};

export const otp = {
  title: "Verifikasi Akun",
  description: `Untuk memverifikasi akun ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"}, masukkan 4 digit kode yang dikirimkan lewat SMS ke nomor ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"}`,
  formList: {
    section_title: "Masukkan OTP",
    formItems: [
      {
        label: "",
        type: "otp",
        placeholder: "",
      },
    ],
  },
  resend_label: "Tidak menerima OTP?",
  resend_link_label: "Kirim Ulang",
  resend_wait_label: "Tunggu 60 detik untuk mengirim ulang OTP",
  cta_label: "Verifikasi",
};
