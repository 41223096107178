"use client"

import { ClientApiList } from "@/lib/api/client-service"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useState } from "react"
import { useForm } from "react-hook-form"

const useReservation = ({
  setIsError,
  captchaRef,
  isConfirmed,
  setIsConfirmed,
  setIsConfirmationOpen,
  setIsLoading,
  setIsPopupVerifyOpen,
}) => {
  const { replace } = useRouter()
  const pathname = usePathname()

  const handleGetData = async () => {
    const { data } = await ClientApiList.getProfileData()
    const dob = new Date(data.data.data.dob)
    const date = dob.getDate()
    const month = dob.getMonth() + 1
    const year = dob.getFullYear()
    return {
      ...data.data.data,
      date,
      month,
      year,
      name,
      "g-recaptcha-response": false,
      city_id: "",
    }
  }

  const resetFormValue = async () => {
    const result = await handleGetData()
    resetForm(result)
    captchaRef.current?.execute()
  }

  const {
    register,
    getValues,
    handleSubmit,
    reset: resetForm,
    setValue,
    watch,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: async () => handleGetData(),
  })

  const [formError, setFormError] = useState(null)

  const reset = () => {
    setFormError(null)
  }

  const onSubmit = async (data) => {
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()
    setFormError(null)

    const bodyForm = {
      event_uuid: data.event_uuid,
      participant: data.participant,
      city: data.city_id,
      venue: data.venue_id,
    }

    if (!token) {
      setIsError(true)
      replace(pathname)
      return
    } else {
      if (!isConfirmed) {
        setIsConfirmed(true)
        setIsConfirmationOpen(true)
        return
      }
      setIsError(false)
      setIsLoading(true)

      try {
        const { data } = await ClientApiList.submitRSVP(bodyForm)

        if (!data.data.data.is_email_verified) {
          setIsPopupVerifyOpen(true)
          // replace(
          //   "/event/reservation/otp/br?uuid=" + data.data.data.rsvp.event_uuid
          // )
        } else {
          replace(
            "/event/reservation/summary/br?uuid=" +
              data.data.data.rsvp.event_uuid
          )
        }
      } catch (err) {
        console.log("[Error]", err)
      }
    }
  }

  return {
    register,
    getValues,
    errors,
    formError,
    resetForm,
    reset,
    watch,
    setError,
    setFocus,
    setValue,
    onSubmit: handleSubmit(onSubmit),
  }
}

export default useReservation
