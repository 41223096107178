"use client"
import React, { useContext, useEffect, useState } from "react"
import ButtonBack from "../shared/button-back/button-back"
import PropTypes from "prop-types"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname, useSearchParams } from "next/navigation"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { Spinner } from "../shared/spinner"
import { PrevPath } from "@/hooks/use-prev-path-ctx"
import Link from "@/components/shared/link/link"
import { useWindowSize } from "@/hooks/use-window-size"
import { Image } from "../shared/image/image"
import { useRedirect } from "@/hooks/use-redirect"

export function ArticleDetailsPageLayout({
  children,
  bg_img_url_mobile,
  bg_img_url_desktop,
}) {
  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const { isLoading, isLogin } = useIsLogin()
  const pathname = usePathname()
  const { prevPath } = useContext(PrevPath)
  const isMobile = useIsMobile()
  const searchParams = useSearchParams()

  const [showPage, setShowPage] = useState(false)
  const [path, setPath] = useState("")
  const [backPath, setBackPath] = useState(prevPath)

  const checkBrPath = pathname.includes("/br") && !isLogin && !isLoading
  const checkUbPath = pathname.includes("/ub") && isLogin && !isLoading
  const hrefBack = isLogin && !isLoading ? "/br/archive" : "/ub"
  const bgImg = process.env.NEXT_PUBLIC_NAME === "marlboro" ? null : isMobile ? bg_img_url_mobile : bg_img_url_desktop

  useShowLoginPopup({
    showWhen: checkBrPath,
    closePath: path,
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  useRedirect({
    redirectWhen: checkUbPath,
    path: path,
    withHash: true,
    withQuery: true,
  })

  useEffect(() => {
    if (!isLoading) setShowPage(true)
  }, [isLoading])

  useEffect(() => {
    if (checkUbPath) {
      setPath("/br/archive")
    } else if (checkBrPath) {
      setPath("/")
    }
  }, [checkUbPath, checkBrPath])

  useEffect(() => {
    if (searchParams.get("page") || searchParams.get("subcategory"))
      setBackPath(
        `${prevPath}?page=${searchParams.get("page")}&subcategory=${searchParams.get("subcategory")}`
      )

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchParams])

  const page = typeof window !== "undefined" && document.body.offsetHeight > 0

  return (
    <div
      className={clsx(
        "relative pb-2.5",
        pathname.includes('why-not') ? 'mt-0' : isDesktop ? "mt-[72px] mx-[15px]" : "mt-[58px]",
      )}
    >
      <Link href={prevPath != null ? backPath : hrefBack} passHref>
        <ButtonBack />
      </Link>
      <div
        className={clsx(
          "absolute left-0 right-0 w-full bg-bg-1 z-1 overflow-hidden",
          isMobile
            ? "h-[738px] rounded-b-[10px] top-[0px] "
            : "min-h-[520px] aspect-[1410/520] top-[16px] rounded-[15px]"
        )}
      >
        {bgImg && <Image alt="bg" src={bgImg} />}
      </div>
      {showPage && document.body.offsetHeight > 0 ? (
        children
      ) : (
        <div className="h-[calc(100vh-170px)] flex items-center">
          <Spinner />
        </div>
      )}
    </div>
  )
}

ArticleDetailsPageLayout.propTypes = {
  children: PropTypes.array,
  bg_img_url_mobile: PropTypes.string,
  bg_img_url_desktop: PropTypes.string,
}
